import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  LockClosedIcon,
  UserIcon,
  CalendarIcon,
  MailIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/solid";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { Routes } from "utils/routes";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import BgImage from "assets/img/illustrations/signin.svg";
import { registerFun } from "redux/auth/action";
import { useDispatch } from "react-redux";

export default () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const today = new Date();
  const SignUpSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Name is required")
      .min(3, "Too Short!")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(50, "Too Long!"),
    password: Yup.string()
      .trim()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
    phone: Yup.string().trim().required("Phone number is required"),
    email: Yup.string().trim().email().required("Email is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date()
      .transform((value) => {
        return value ? moment(value).toDate() : value;
      })
      .required("Date of Birth is required")
      .max(today, "Future date not allowed"),
  });

  const SignUpFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      phone: "",
      password: "",
      email: "",
      dob: "",
      gender: "",
    },
    validationSchema: SignUpSchema,
    onSubmit: async (values, action) => {
      let validPhone = isValidPhoneNumber(values.phone);
      if (validPhone === false) {
        SignUpFormik.errors["phone"] = "Invalid phone number";
      }
      setDisableSubmitButton(!disableSubmitButton);
      let updatedObj = { ...values };
      updatedObj.dob = moment(updatedObj.dob).format("YYYY-MM-DD");
      let isSubmit = await dispatch(registerFun(updatedObj, history));
      setDisableSubmitButton(isSubmit.isSubmitButton);
    },
  });

  const [value, setValue] = useState();
  useEffect(() => {
    SignUpFormik.values.phone = value;
  }, [value]);

  const [birthday, setBirthday] = useState("");
  useEffect(() => {
    SignUpFormik.values.dob = birthday;
  }, [birthday]);

  const [genn, setGenn] = useState("");
  useEffect(() => {
    SignUpFormik.values.gender = genn;
  }, [genn]);

  const [showPassword, setShowPassword] = useState(false);
  return (
    <main className="my-5">
      <section className=" mt-3 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Register as User</h3>
                </div>
                <Form
                  className="mt-4 signUp_Form"
                  onSubmit={SignUpFormik.handleSubmit}
                >
                  <Form.Group id="name" className="mb-4">
                    <Form.Label>Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <UserIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        name="name"
                        value={SignUpFormik.values.name}
                        onChange={(e) => {
                          SignUpFormik.setFieldValue("name", e.target.value);
                        }}
                        type="text"
                        placeholder="Enter Full Name"
                      />
                      <Form.Control.Feedback type="invalid">
                        {SignUpFormik?.errors?.name}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type="email"
                        name="email"
                        value={SignUpFormik.values.email}
                        onChange={(e) => {
                          SignUpFormik.setFieldValue("email", e.target.value);
                        }}
                        placeholder="Enter email"
                      />
                      <Form.Control.Feedback type="invalid">
                        {SignUpFormik?.errors?.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="birthday">
                    <Form.Label>Date of Birth</Form.Label>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={setBirthday}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <CalendarIcon className="icon icon-xs" />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            value={
                              birthday
                                ? moment(birthday).format("YYYY-MM-DD")
                                : ""
                            }
                            placeholder="YYYY-MM-DD"
                            onFocus={openCalendar}
                            onChange={() => {}}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            style={{ display: "block" }}
                          >
                            {SignUpFormik?.errors?.dob}
                          </Form.Control.Feedback>
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                  <Form.Group id="gender">
                    <Form.Label>Gender</Form.Label>
                    <InputGroup>
                      <Form.Select
                        value={genn}
                        onChange={(e) => {
                          setGenn(e.target.value);
                        }}
                        className="mb-0"
                        multiple={false}
                      >
                        <option defaultValue>Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {SignUpFormik?.errors?.gender}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="phone" className="mb-4">
                    <Form.Label>Phone Number</Form.Label>
                    <InputGroup>
                      <PhoneInput
                        country={"us"}
                        type="text"
                        label="Phone Number"
                        placeholder="Phone Number"
                        isInvalid
                        className="phoneNumberField form-control"
                        onChange={setValue}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: "block" }}
                      >
                        {SignUpFormik?.errors?.phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={SignUpFormik.values.password}
                        onChange={(e) => {
                          SignUpFormik.setFieldValue(
                            "password",
                            e.target.value
                          );
                        }}
                        placeholder="Password"
                      />
                      <InputGroup.Text
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <EyeIcon className="icon icon-xs  text-gray-600" />
                        ) : (
                          <EyeOffIcon className="icon icon-xs text-gray-600" />
                        )}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {SignUpFormik?.errors?.password}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <div className="d-grid">
                    <Button
                      variant="gray-800"
                      type="submit"
                      onClick={SignUpFormik.handleSubmit}
                      disabled={disableSubmitButton}
                    >
                      {disableSubmitButton ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Sign up"
                      )}
                    </Button>
                  </div>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link
                      as={Link}
                      to={Routes.Signin.path}
                      className="fw-bold"
                    >
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
