import * as types from "./contants";
import customAxios from "../../config/index";
import { toast } from "react-toastify";

// Get Department Function
export const getDepartFun = (page, search) => async (dispatch) => {
  try {
    let URL = "departments/";
    if (page) {
      URL = URL + `?page=${page}`;
    }
    if (search) {
      URL = URL + `&search=${search}`;
    }
    const response = await customAxios.get(URL);
    dispatch({
      type: types.GET_DEPARTMENT,
      payload: response.data,
    });
    // if (response.data.data.length == 0) {
    //   toast.error("No Record Found!");
    // }
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Add Department Function
export const addDepartFun = (data) => async (dispatch) => {
  try {
    const response = await customAxios.post("departments/add/", data);
    toast.success(response.data.detail);
    dispatch(getDepartFun(1));
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Edit Department Function
export const editDepartFun = (data, id) => async (dispatch) => {
  try {
    const response = await customAxios.patch(`departments/edit/${id}`, data);
    toast.success("Successfully Updated");
    dispatch({
      type: types.EDIT_DEPARTMENT,
      payload: response.data,
    });
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Delete Department Function
export const delDepartFun = (id) => async (dispatch) => {
  try {
    const response = await customAxios.delete(`departments/delete/${id}`);
    toast.success(response.data.detail);
    dispatch(getDepartFun(1));
  } catch (error) {
    toast.error(error.response.data.detail);
  }
};

// Get Designation Function
export const getDesignFun = (page, search) => async (dispatch) => {
  try {
    let URL = "designations/";
    if (page) {
      URL = URL + `?page=${page}`;
    }
    if (search) {
      URL = URL + `&search=${search}`;
    }
    const response = await customAxios.get(URL);
    dispatch({
      type: types.GET_DESIGNATION,
      payload: response.data,
    });
    // if (response.data.data.length == 0) {
    //   toast.error("No Record Found!");
    // }
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Add Designation Function
export const addDesigntFun = (data) => async (dispatch) => {
  try {
    const response = await customAxios.post("designations/add/", data);
    toast.success(response.data.detail);
    dispatch(getDesignFun(1));
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Edit Designation Function
export const editDesigntFun = (data, id) => async (dispatch) => {
  try {
    const response = await customAxios.patch(`designations/edit/${id}`, data);
    dispatch({
      type: types.EDIT_DESIGNATION,
      payload: response.data,
    });
    toast.success("Successfully Updated");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Delete Department Function
export const delDesigntFun = (id) => async (dispatch) => {
  try {
    const response = await customAxios.delete(`designations/delete/${id}`);
    toast.success(response.data.detail);
    dispatch(getDesignFun(1));
  } catch (error) {
    toast.error(error.response.data.detail);
  }
};
