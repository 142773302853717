import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  LockClosedIcon,
  UserIcon,
  CalendarIcon,
  MailIcon,
  EyeIcon,
  EyeOffIcon,
  AcademicCapIcon,
  ViewGridIcon,
} from "@heroicons/react/solid";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import BgImage from "assets/img/illustrations/signin.svg";
import { accSetUpFun } from "redux/doctor/action";
import { useDispatch, useSelector } from "react-redux";
import { getDepartFun, getDesignFun } from "redux/departDesign/action";

export default () => {
  const dispatch = useDispatch();
  let history = useHistory();
  let userDetail = useSelector((state) => state.auth);
  let options = useSelector((state) => state.accountSetup);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const today = moment(new Date()).format("YYYY-MM-DD");

  useEffect(() => {
    if (userDetail.isAccountSetup || userDetail.isAdmin === true) {
      history.push("/");
    } else {
      dispatch(getDesignFun());
      dispatch(getDepartFun());
    }
  }, []);

  const FormikSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Name is required")
      .min(3, "Too Short!")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(50, "Too Long!"),
    oldPassword: Yup.string()
      .trim()
      .required("Old Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
    newPassword: Yup.string()
      .trim()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
    phone: Yup.string().trim().required("Phone number is required"),
    email: Yup.string().trim().email().required("Email is required"),
    gender: Yup.string().required("Gender is required"),
    designation: Yup.string().required("Designation is required"),
    department: Yup.string().required("Department is required"),
    dob: Yup.date()
      .required("Date of Birth is required")
      .max(today, "Future date not allowed"),
    specialty: Yup.string()
      .trim()
      .required("Specialty is required")
      .min(3, "Too Short!")
      .max(50, "Too Long!"),
    qualification: Yup.string()
      .trim()
      .required("Qualification is required")
      .min(3, "Too Short!")
      .max(50, "Too Long!"),
  });

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      phone: "",
      newPassword: "",
      oldPassword: "",
      email: userDetail.email,
      dob: "",
      gender: "",
      designation: "",
      department: "",
      specialty: "",
      qualification: "",
    },
    validationSchema: FormikSchema,
    onSubmit: async (values, action) => {
      let validPhone = isValidPhoneNumber(values.phone);
      if (validPhone === false) {
        Formik.errors["phone"] = "Invalid phone number";
      }
      if (values.oldPassword == values.newPassword) {
        toast.error("Invalid new password");
        return 0;
      }
      setDisableSubmitButton(!disableSubmitButton);
      let updatedObj = { ...values };
      updatedObj.dob = moment(updatedObj.dob).format("YYYY-MM-DD");
      let isSubmit = await dispatch(accSetUpFun(updatedObj, history));
      setDisableSubmitButton(isSubmit.isSubmitButton);
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setOldPassword] = useState(false);

  return (
    <main className="my-5">
      <section className=" mt-3 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Account Setup</h3>
                </div>
                <Form
                  className="mt-4 signUp_Form"
                  onSubmit={Formik.handleSubmit}
                >
                  <Form.Group id="name" className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <UserIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        name="name"
                        value={Formik.values.name}
                        onChange={Formik.handleChange}
                        type="text"
                        placeholder="Enter Full Name"
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.name}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="email" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type="email"
                        name="email"
                        value={Formik.values.email}
                        disabled
                        onChange={Formik.handleChange}
                        placeholder="Enter email"
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="birthday" className="mb-3">
                    <Form.Label>Date of Birth</Form.Label>
                    <Datetime
                      closeOnSelect={true}
                      timeFormat={false}
                      onChange={(date) => {
                        Formik.setFieldValue(
                          "dob",
                          moment(date).format("YYYY-MM-DD")
                        );
                      }}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <CalendarIcon className="icon icon-xs" />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            value={Formik.values.dob}
                            placeholder="YYYY-MM-DD"
                            onFocus={openCalendar}
                            onChange={() => {}}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            style={{ display: "block" }}
                          >
                            {Formik?.errors?.dob}
                          </Form.Control.Feedback>
                        </InputGroup>
                      )}
                    />
                  </Form.Group>

                  <Form.Group id="gender" className="mb-3">
                    <Form.Label>Gender</Form.Label>
                    <InputGroup>
                      <Form.Select
                        value={Formik.values.gender}
                        onChange={(e) => {
                          Formik.setFieldValue("gender", e.target.value);
                        }}
                        className="mb-0"
                        multiple={false}
                      >
                        <option defaultValue>Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.gender}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="designation" className="mb-3">
                    <Form.Label>Designation</Form.Label>
                    <InputGroup>
                      <Form.Select
                        value={Formik.values.designation}
                        onChange={(e) => {
                          Formik.setFieldValue("designation", e.target.value);
                        }}
                        className="mb-0"
                        multiple={false}
                      >
                        <option defaultValue>Designation</option>
                        {options.designation.data?.map((val, index) => (
                          <option key={index} value={val.name}>
                            {val.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.designation}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="department" className="mb-3">
                    <Form.Label>Department</Form.Label>
                    <InputGroup>
                      <Form.Select
                        value={Formik.values.department}
                        onChange={(e) => {
                          Formik.setFieldValue("department", e.target.value);
                        }}
                        className="mb-0"
                        multiple={false}
                      >
                        <option defaultValue>Department</option>
                        {options.department.data?.map((val, index) => (
                          <option key={index} value={val.name}>
                            {val.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.department}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="specialty" className="mb-3">
                    <Form.Label>Specialty</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <ViewGridIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        name="specialty"
                        value={Formik.values.specialty}
                        onChange={Formik.handleChange}
                        type="text"
                        placeholder="Enter Specialty"
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.specialty}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="qualification" className="mb-3">
                    <Form.Label>Qualification</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <AcademicCapIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        name="qualification"
                        value={Formik.values.qualification}
                        onChange={Formik.handleChange}
                        type="text"
                        placeholder="Enter Qualification"
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.qualification}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="phone" className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <InputGroup>
                      <PhoneInput
                        country={"us"}
                        type="text"
                        label="Phone Number"
                        value={Formik.values.phone}
                        placeholder="Phone Number"
                        isinvalid="true"
                        className="phoneNumberField form-control"
                        onChange={(phone) => {
                          Formik.setFieldValue("phone", phone);
                        }}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: "block" }}
                      >
                        {Formik?.errors?.phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="oldPassword" className="mb-3">
                    <Form.Label>Old Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showOldPassword ? "text" : "password"}
                        name="oldPassword"
                        value={Formik.values.oldPassword}
                        onChange={Formik.handleChange}
                        isInvalid={false}
                        placeholder="Old Password"
                      />
                      <InputGroup.Text
                        onClick={() => {
                          setOldPassword(!showOldPassword);
                        }}
                      >
                        {showOldPassword ? (
                          <EyeIcon className="icon icon-xs  text-gray-600" />
                        ) : (
                          <EyeOffIcon className="icon icon-xs text-gray-600" />
                        )}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.oldPassword}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="newPassword" className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="newPassword"
                        value={Formik.values.newPassword}
                        onChange={Formik.handleChange}
                        placeholder="New Password"
                      />
                      <InputGroup.Text
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <EyeIcon className="icon icon-xs  text-gray-600" />
                        ) : (
                          <EyeOffIcon className="icon icon-xs text-gray-600" />
                        )}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.newPassword}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <div className="d-grid">
                    <Button
                      variant="gray-800"
                      type="submit"
                      onClick={Formik.handleSubmit}
                      disabled={disableSubmitButton}
                    >
                      {disableSubmitButton ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
