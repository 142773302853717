import React, { useState, useEffect } from "react";
import { HomeIcon, PlusIcon, CalendarIcon } from "@heroicons/react/solid";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { TrashIcon, EyeIcon } from "@heroicons/react/outline";
import {
  Breadcrumb,
  Button,
  Modal,
  Form,
  InputGroup,
  Spinner,
  Col,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobleTable from "pages/tables/GlobleTable";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { customSelectStyles } from "utils/Global";
import _uniqueId from "lodash/uniqueId";
import { useHistory } from "react-router-dom";
import { getDepartFun } from "redux/departDesign/action";
import {
  addSchedulerFun,
  delSchedulerFun,
  editSchedulerFun,
  getFilteredSchedulerFun,
  getSchedulerFun,
} from "redux/scheduler/action";
import { Routes } from "utils/routes";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDefault, setShowDefault] = useState(false);
  const [departmentObj, setDepartmentObj] = useState("");
  let schedules = useSelector((state) => state.scheduler);
  const [schedulesData, setSchedulesData] = useState({});
  const handleClose = () => {
    setShowDefault(false);
  };
  let options = useSelector((state) => state.accountSetup.departmentData);
  options = options.map(({ name: label, id: value, ...rest }) => ({
    label,
    value,
    ...rest,
  }));

  let filteredOptions = useSelector(
    (state) => state.accountSetup.departmentData
  );
  filteredOptions = filteredOptions.map(
    ({ name: label, id: value, ...rest }) => ({
      label,
      value,
      ...rest,
    })
  );
  filteredOptions = [{ label: "All", value: "" }, ...filteredOptions];

  const [isEdit, setIsEdit] = useState(false);

  const [userObj, setUserObj] = useState({});

  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-gray",
      },
      buttonsStyling: false,
    })
  );

  const onClickAlert = async function (obj) {
    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "",
      text: "Are you sure do you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      await dispatch(delSchedulerFun(obj?.id));
    }
  };

  const viewOnCalendar = (obj) => {
    history.push({
      pathname: Routes.Calendar.path,
      state: {
        obj: obj,
      },
    });
  };

  const toggleChange = async (id, val) => {
    await dispatch(editSchedulerFun({ isActive: val }, id));
  };

  const columns = [
    { dataField: "id", text: "ID", hidden: true, csvExport: false },
    { dataField: "name", text: "Name", sort: true },
    {
      dataField: "department",
      text: "Department",
      csvExport: true,
      csvFormatter: (col) => {
        let result = options.find((item) => item.value == col);
        return result?.label;
      },
      formatter: (col) => {
        let result = options.find((item) => item.value == col);
        return result?.label;
      },
    },
    {
      dataField: "startDate",
      text: "Start Date",
      csvExport: true,
      csvFormatter: (col) => (col ? moment(col).format("DD-MMMM-YYYY") : "-"),
      formatter: (col) => (col ? moment(col).format("DD-MMMM-YYYY") : "-"),
    },
    {
      dataField: "endDate",
      text: "End Date",
      csvExport: true,
      csvFormatter: (col) => (col ? moment(col).format("DD-MMMM-YYYY") : "-"),
      formatter: (col) => (col ? moment(col).format("DD-MMMM-YYYY") : "-"),
    },
    {
      dataField: "isActive",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <>
            <Form.Check
              type="switch"
              label=""
              checked={row?.isActive}
              onChange={(e) => {
                toggleChange(row?.id, e.target.checked);
              }}
            />
          </>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      csvExport: false,
      formatter: (cellContent, row) => {
        return (
          <>
            <EyeIcon
              id={_uniqueId("icon-")}
              className="icon icon-xs me-3"
              onClick={() => {
                viewOnCalendar(row);
              }}
              style={{ cursor: "pointer" }}
            />
            <TrashIcon
              id={_uniqueId("icon-")}
              className="icon icon-xs"
              style={{ cursor: "pointer" }}
              onClick={() => {
                onClickAlert(row);
              }}
            />
          </>
        );
      },
    },
  ];

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const formikSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    department: Yup.string().trim().required("Department is required"),
    startDate: Yup.date().required("Start Date is Required"),
    endDate: Yup.date().min(
      Yup.ref("startDate"),
      "End date can't be before start date"
    ),
  });

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userObj?.name ? userObj.name : "",
      department: userObj?.department ? userObj.department : "",
      startDate: userObj?.startDate
        ? moment(userObj.startDate).format("DD-MMMM-YYYY")
        : moment().format("DD-MMMM-YYYY"),
      endDate: userObj?.endDate
        ? moment(userObj.endDate).format("DD-MMMM-YYYY")
        : moment().format("DD-MMMM-YYYY"),
    },
    validationSchema: formikSchema,
    onSubmit: async (values, action) => {
      let updateObj = {
        name: values.name,
        department: values.department,
        isActive: false,
        startDate: moment(values.startDate).format("YYYY-MM-DD"),
        endDate: moment(values.endDate).format("YYYY-MM-DD"),
      };
      setDisableSubmitButton(!disableSubmitButton);
      let isSubmit = "";
      if (isEdit) {
        isSubmit = await dispatch(editSchedulerFun(updateObj, userObj?.id));
      } else {
        isSubmit = await dispatch(addSchedulerFun(updateObj));
      }
      if (!isSubmit.isSubmitButton) {
        setDisableSubmitButton(isSubmit.isSubmitButton);
        handleClose();
        Formik.resetForm();
      }
    },
  });

  useEffect(() => {
    dispatch(getSchedulerFun(1));
    dispatch(getDepartFun());
  }, []);

  useEffect(() => {
    if (departmentObj) {
      dispatch(getFilteredSchedulerFun(1, "", departmentObj?.value));
    }
  }, [departmentObj]);

  useEffect(() => {
    setSchedulesData({ ...schedules });
  }, [schedules]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-1">
        <div>
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Schedule</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h4>Schedule List</h4>
            </div>
          </div>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            variant="tertiary"
            size="sm"
            className="d-inline-flex align-items-center"
            onClick={() => {
              setIsEdit(false);
              setShowDefault(true);
              setUserObj({});
            }}
          >
            <PlusIcon className="icon icon-xs me-2" /> Add Schedule
          </Button>
        </div>
      </div>
      <Row>
        <Col lg={3} md={4} sm={12} xs={12} className="mt-2">
          {/* <Form.Label>Department Filter</Form.Label> */}
          <Form.Group id="department" className="mb-3">
            <Select
              placeholder="Select Department"
              isSearchable={true}
              options={filteredOptions}
              value={departmentObj}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#61DAFB",
                  primary: "#61DAFB",
                },
              })}
              styles={customSelectStyles}
              onChange={(arr) => {
                setDepartmentObj(arr);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <GlobleTable
        placeholder="Search Schedule"
        data={schedulesData?.data}
        columns={columns}
        totalEntries={schedulesData?.allScheduler?.totalEntries}
        totalPage={schedulesData?.allScheduler?.totalPages}
        tableType={"schedules"}
        currentPage={schedulesData?.allScheduler?.currentPage}
        selectedDepartment={departmentObj}
      />
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">
            {isEdit ? "Edit " : "Add "} Schedule
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={Formik.handleSubmit} className="mt-0 signUp_Form">
          <Modal.Body>
            <Row>
              <Col lg={12} xs={12} className="mt-2">
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    autoFocus
                    type="name"
                    name="name"
                    value={Formik.values.name}
                    onChange={(e) => {
                      Formik.setFieldValue("name", e.target.value);
                    }}
                    placeholder="Enter name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {Formik?.errors?.name}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
              <Col lg={12} xs={12} className="mt-2">
                <Form.Label>Department</Form.Label>
                <Form.Group id="department" className="mb-3">
                  <Select
                    isSearchable={true}
                    options={options}
                    value={options.find(
                      (item) => item.value == Formik.values.department
                    )}
                    isDisabled={isEdit}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#61DAFB",
                        primary: "#61DAFB",
                      },
                    })}
                    styles={customSelectStyles}
                    onChange={(arr) => {
                      Formik.setFieldValue("department", arr.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {Formik?.errors?.department}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group id="startDate">
                  <Form.Label>Select start date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={(date) => {
                      Formik.setFieldValue(
                        "startDate",
                        moment(date).format("DD-MMMM-YYYY")
                      );
                    }}
                    isValidDate={(currDate) =>
                      currDate.isSameOrAfter(Formik.values.startDate)
                    }
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <CalendarIcon className="icon icon-xs" />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          placeholder="YYYY-MM-DD"
                          value={Formik.values.startDate}
                          onFocus={openCalendar}
                          onChange={() => {}}
                        />
                        <Form.Control.Feedback type="invalid">
                          {Formik?.errors?.startDate}
                        </Form.Control.Feedback>
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group id="endDate" className="mb-2">
                  <Form.Label>Select end date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={(date) => {
                      Formik.setFieldValue(
                        "endDate",
                        moment(date).format("DD-MMMM-YYYY")
                      );
                    }}
                    isValidDate={(currDate) =>
                      currDate.isSameOrAfter(Formik.values.startDate)
                    }
                    initialViewDate={
                      Formik.values.endDate || Formik.values.startDate
                    }
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <CalendarIcon className="icon icon-xs" />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          placeholder="YYYY-MM-DD"
                          value={Formik.values.endDate}
                          onFocus={openCalendar}
                          onChange={() => {}}
                        />
                        <Form.Control.Feedback type="invalid">
                          {Formik?.errors?.endDate}
                        </Form.Control.Feedback>
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              disabled={disableSubmitButton}
            >
              {disableSubmitButton ? (
                <Spinner animation="border" size="sm" />
              ) : isEdit ? (
                "Save Changes"
              ) : (
                "Save"
              )}
            </Button>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
