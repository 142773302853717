import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Spinner, Tab, Nav } from "react-bootstrap";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import {
  LockClosedIcon,
  UserIcon,
  MailIcon,
  CalendarIcon,
  EyeIcon,
  AcademicCapIcon,
  ViewGridIcon,
  EyeOffIcon,
} from "@heroicons/react/solid";
import { Button, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { profileUpdateFun } from "redux/auth/action";
import { getDepartFun, getDesignFun } from "redux/departDesign/action";
import { useDispatch, useSelector } from "react-redux";
import { CapitalizeFirstLetter } from "utils/Global";
import { toast } from "react-toastify";

export default () => {
  let object = ["profile settings", "security"];
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.auth);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  let options = useSelector((state) => state.accountSetup);

  const today = moment(new Date()).format("YYYY-MM-DD");

  let initialSchema = {
    name: Yup.string()
      .trim()
      .required("Name is required")
      .min(3, "Too Short!")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(50, "Too Long!"),
    oldPassword: Yup.string()
      .min(6, "Password is too short")
      .when("newPassword", {
        is: (value) => value && value.length > 0,
        then: Yup.string().required(
          "Old password is required when setting new password"
        ),
        otherwise: Yup.string(),
      }),
    newPassword: Yup.string()
      .trim()
      .min(6, "Password is too short - should be 6 chars minimum"),
    email: Yup.string().trim().email().required("Email is required"),
    phone: Yup.string().trim().required("Phone number is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date()
      .required("Date of Birth is required")
      .max(today, "Future date not allowed"),
  };

  const FormikSchema = Yup.object().shape(
    userDetail?.doctor
      ? {
          ...initialSchema,
          designation: Yup.string().required("Designation is required"),
          department: Yup.string().required("Department is required"),
          specialty: Yup.string()
            .trim()
            .required("Specialty is required")
            .min(3, "Too Short!")
            .max(50, "Too Long!"),
          qualification: Yup.string()
            .trim()
            .required("Qualification is required")
            .min(3, "Too Short!")
            .max(50, "Too Long!"),
        }
      : initialSchema
  );

  let initialObj = {
    name: userDetail?.name?.length ? userDetail?.name : "",
    email: userDetail?.email?.length ? userDetail?.email : "",
    phone: userDetail?.phone?.length ? userDetail?.phone : "",
    dob: userDetail?.dob?.length ? userDetail?.dob : today,
    gender: userDetail?.gender?.length ? userDetail?.gender : "",
    oldPassword: "",
    newPassword: "",
  };

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: userDetail?.doctor
      ? {
          ...initialObj,
          designation:
            userDetail?.doctor && userDetail?.doctor?.designation?.length
              ? userDetail?.doctor?.designation
              : "",
          department:
            userDetail?.doctor && userDetail?.doctor?.department?.length
              ? userDetail?.doctor?.department
              : "",
          specialty:
            userDetail?.doctor && userDetail?.doctor?.specialty?.length
              ? userDetail?.doctor?.specialty
              : "",
          qualification:
            userDetail?.doctor && userDetail?.doctor?.qualification?.length
              ? userDetail?.doctor?.qualification
              : "",
        }
      : initialObj,
    validationSchema: FormikSchema,
    onSubmit: async (values, action) => {
      if (
        tabValue === "security" &&
        (values.oldPassword.length === 0 || values.newPassword.length === 0)
      ) {
        toast.error("Fields are required!");
        return 0;
      }

      if (tabValue === "security" && values.oldPassword == values.newPassword) {
        toast.error("Old and new passwords cannot be same");
        return 0;
      }

      let validPhone = isValidPhoneNumber(values.phone);
      if (validPhone === false) {
        Formik.errors["phone"] = "Invalid phone number";
      }
      setDisableSubmitButton(!disableSubmitButton);
      let updatedObj = { ...values };
      updatedObj.dob = moment(updatedObj.dob).format("YYYY-MM-DD");
      let isSubmit = await dispatch(profileUpdateFun(updatedObj));
      setDisableSubmitButton(isSubmit.isSubmitButton);
    },
  });

  useEffect(() => {
    if (userDetail?.doctor) {
      dispatch(getDesignFun());
      dispatch(getDepartFun());
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setOldPassword] = useState(false);
  const [tabValue, setTabValue] = useState("profile settings");
  return (
    <>
      <Row className="py-4">
        <Col xs={12} xl={12}>
          <Card className="p-3">
            <Tab.Container defaultActiveKey="profile settings">
              <Row>
                <Col lg={12}>
                  <Nav className="nav-tabs">
                    {object.map((val, index) => {
                      return (
                        <Nav.Item key={index}>
                          <Nav.Link
                            eventKey={val}
                            onClick={() => {
                              setTabValue(val);
                            }}
                            className="mb-sm-3 mb-md-0"
                          >
                            {CapitalizeFirstLetter(val)}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Col>
                <Col lg={12}>
                  <Tab.Content>
                    {object.map((val, index) => {
                      return (
                        <Tab.Pane key={index} eventKey={val} className="py-4">
                          <Form
                            onSubmit={Formik.handleSubmit}
                            className="signUp_Form"
                          >
                            <Row>
                              {tabValue === "profile settings" && (
                                <>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="name">
                                      <Form.Label>Name</Form.Label>
                                      <InputGroup>
                                        <InputGroup.Text>
                                          <UserIcon className="icon icon-xs text-gray-600" />
                                        </InputGroup.Text>
                                        <Form.Control
                                          autoFocus
                                          name="name"
                                          value={Formik.values.name}
                                          onChange={Formik.handleChange}
                                          type="text"
                                          placeholder="Enter Full Name"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {Formik?.errors?.name}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="email">
                                      <Form.Label>Email</Form.Label>
                                      <InputGroup>
                                        <InputGroup.Text>
                                          <MailIcon className="icon icon-xs text-gray-600" />
                                        </InputGroup.Text>
                                        <Form.Control
                                          autoFocus
                                          type="email"
                                          name="email"
                                          disabled
                                          onChange={Formik.handleChange}
                                          placeholder={Formik.values.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {Formik?.errors?.email}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="birthday">
                                      <Form.Label>Date of Birth</Form.Label>
                                      <Datetime
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        onChange={(date) => {
                                          Formik.setFieldValue(
                                            "dob",
                                            moment(date).format("YYYY-MM-DD")
                                          );
                                        }}
                                        renderInput={(props, openCalendar) => (
                                          <InputGroup>
                                            <InputGroup.Text>
                                              <CalendarIcon className="icon icon-xs" />
                                            </InputGroup.Text>
                                            <Form.Control
                                              required
                                              type="text"
                                              value={Formik.values.dob}
                                              placeholder="YYYY-MM-DD"
                                              onFocus={openCalendar}
                                              onChange={() => {}}
                                            />
                                            <Form.Control.Feedback
                                              type="invalid"
                                              style={{ display: "block" }}
                                            >
                                              {Formik?.errors?.dob}
                                            </Form.Control.Feedback>
                                          </InputGroup>
                                        )}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="gender">
                                      <Form.Label>Gender</Form.Label>
                                      <InputGroup>
                                        <Form.Select
                                          value={Formik.values.gender}
                                          onChange={(e) => {
                                            Formik.setFieldValue(
                                              "gender",
                                              e.target.value
                                            );
                                          }}
                                          className="mb-0"
                                          multiple={false}
                                        >
                                          <option defaultValue>Gender</option>
                                          <option value="male">Male</option>
                                          <option value="female">Female</option>
                                          <option value="other">Other</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                          {Formik?.errors?.gender}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  {userDetail?.doctor && (
                                    <>
                                      <Col md={6} className="mb-3">
                                        <Form.Group id="designation">
                                          <Form.Label>Designation</Form.Label>
                                          <InputGroup>
                                            <Form.Select
                                              value={Formik.values.designation}
                                              onChange={(e) => {
                                                Formik.setFieldValue(
                                                  "designation",
                                                  e.target.value
                                                );
                                              }}
                                              className="mb-0"
                                              multiple={false}
                                            >
                                              <option defaultValue>
                                                Designation
                                              </option>
                                              {options.designation.data?.map(
                                                (val, index) => (
                                                  <option
                                                    key={index}
                                                    value={val.name}
                                                  >
                                                    {val.name}
                                                  </option>
                                                )
                                              )}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                              {Formik?.errors?.designation}
                                            </Form.Control.Feedback>
                                          </InputGroup>
                                        </Form.Group>
                                      </Col>

                                      <Col md={6} className="mb-3">
                                        <Form.Group id="department">
                                          <Form.Label>Department</Form.Label>
                                          <InputGroup>
                                            <Form.Select
                                              value={Formik.values.department}
                                              onChange={(e) => {
                                                Formik.setFieldValue(
                                                  "department",
                                                  e.target.value
                                                );
                                              }}
                                              className="mb-0"
                                              multiple={false}
                                            >
                                              <option defaultValue>
                                                Department
                                              </option>
                                              {options.department.data?.map(
                                                (val, index) => (
                                                  <option
                                                    key={index}
                                                    value={val.name}
                                                  >
                                                    {val.name}
                                                  </option>
                                                )
                                              )}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                              {Formik?.errors?.department}
                                            </Form.Control.Feedback>
                                          </InputGroup>
                                        </Form.Group>
                                      </Col>

                                      <Col md={6} className="mb-3">
                                        <Form.Group id="specialty">
                                          <Form.Label>Specialty</Form.Label>
                                          <InputGroup>
                                            <InputGroup.Text>
                                              <ViewGridIcon className="icon icon-xs text-gray-600" />
                                            </InputGroup.Text>
                                            <Form.Control
                                              autoFocus
                                              name="specialty"
                                              value={Formik.values.specialty}
                                              onChange={Formik.handleChange}
                                              type="text"
                                              placeholder="Enter Specialty"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {Formik?.errors?.specialty}
                                            </Form.Control.Feedback>
                                          </InputGroup>
                                        </Form.Group>
                                      </Col>

                                      <Col md={6} className="mb-3">
                                        <Form.Group id="qualification">
                                          <Form.Label>Qualification</Form.Label>
                                          <InputGroup>
                                            <InputGroup.Text>
                                              <AcademicCapIcon className="icon icon-xs text-gray-600" />
                                            </InputGroup.Text>
                                            <Form.Control
                                              autoFocus
                                              name="qualification"
                                              value={
                                                Formik.values.qualification
                                              }
                                              onChange={Formik.handleChange}
                                              type="text"
                                              placeholder="Enter Qualification"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {Formik?.errors?.qualification}
                                            </Form.Control.Feedback>
                                          </InputGroup>
                                        </Form.Group>
                                      </Col>
                                    </>
                                  )}
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="phone">
                                      <Form.Label>Phone Number</Form.Label>
                                      <InputGroup>
                                        <PhoneInput
                                          country={"us"}
                                          type="text"
                                          label="Phone Number"
                                          value={Formik.values.phone}
                                          placeholder="Phone Number"
                                          isinvalid="true"
                                          className="phoneNumberField form-control"
                                          onChange={(phone) => {
                                            Formik.setFieldValue(
                                              "phone",
                                              phone
                                            );
                                          }}
                                        />
                                        <Form.Control.Feedback
                                          type="invalid"
                                          style={{ display: "block" }}
                                        >
                                          {Formik?.errors?.phone}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                </>
                              )}

                              {tabValue === "security" && (
                                <>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="oldPassword">
                                      <Form.Label>Old Password</Form.Label>
                                      <InputGroup>
                                        <InputGroup.Text>
                                          <LockClosedIcon className="icon icon-xs text-gray-600" />
                                        </InputGroup.Text>
                                        <Form.Control
                                          id="oldPasswordInput"
                                          type={
                                            showOldPassword
                                              ? "text"
                                              : "password"
                                          }
                                          name="oldPassword"
                                          value={Formik.values.oldPassword}
                                          onChange={Formik.handleChange}
                                          isInvalid={false}
                                          placeholder="Old Password"
                                        />
                                        <InputGroup.Text
                                          onClick={() => {
                                            setOldPassword(!showOldPassword);
                                          }}
                                        >
                                          {showOldPassword ? (
                                            <EyeIcon className="icon icon-xs  text-gray-600" />
                                          ) : (
                                            <EyeOffIcon className="icon icon-xs text-gray-600" />
                                          )}
                                        </InputGroup.Text>
                                        <Form.Control.Feedback type="invalid">
                                          {Formik?.errors?.oldPassword}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>

                                  <Col md={6} className="mb-3">
                                    <Form.Group id="newPassword">
                                      <Form.Label>New Password</Form.Label>
                                      <InputGroup>
                                        <InputGroup.Text>
                                          <LockClosedIcon className="icon icon-xs text-gray-600" />
                                        </InputGroup.Text>
                                        <Form.Control
                                          id="newPasswordInput"
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          name="newPassword"
                                          value={Formik.values.newPassword}
                                          onChange={Formik.handleChange}
                                          placeholder="Password"
                                        />
                                        <InputGroup.Text
                                          onClick={() => {
                                            setShowPassword(!showPassword);
                                          }}
                                        >
                                          {showPassword ? (
                                            <EyeIcon className="icon icon-xs  text-gray-600" />
                                          ) : (
                                            <EyeOffIcon className="icon icon-xs text-gray-600" />
                                          )}
                                        </InputGroup.Text>
                                        <Form.Control.Feedback type="invalid">
                                          {Formik?.errors?.newPassword}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <div className="mt-3">
                              <Button
                                variant="gray-800"
                                className="mt-2 animate-up-2 d-flex align-items-center mb-1"
                                type="submit"
                                disabled={disableSubmitButton}
                                onClick={Formik.handleSubmit}
                              >
                                {disableSubmitButton ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            </div>
                          </Form>
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Card>
        </Col>

        {/* <Col xs={12} xl={4}>
          <Row>
            <Col xs={12} className="mb-4">
              <ProfileCardWidget
                fullName={userDetail?.name}
                picture={Profile1}
                jobTitle={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.`}
                location=""
              />
            </Col>
            <Col xs={12} sm={6} xl={12}>
              <Form
                {...getRootProps({
                  className:
                    "dropzone rounded d-flex align-items-center justify-content-center mb-4",
                })}
              >
                <Form.Control {...getInputProps()} />
                <div className="dz-default dz-message text-center">
                  <p className="dz-button mb-0">Drop files here to upload</p>
                </div>
              </Form>
              <Row className="dropzone-files">
                {files.map((file) => (
                  <DropzoneFile key={file.path} {...file} />
                ))}
              </Row>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </>
  );
};
