import React, { useState } from "react";
import {
  LockClosedIcon,
  MailIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/solid";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Routes } from "utils/routes";
import BgImage from "assets/img/illustrations/signin.svg";
import { loginFun } from "redux/auth/action";
import { useDispatch } from "react-redux";

export default () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const SignInSchema = Yup.object().shape({
    username: Yup.string().trim().email().required("Email is required"),
    password: Yup.string()
      .trim()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
  });

  const SignInFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: SignInSchema,
    onSubmit: async (values, action) => {
      setDisableSubmitButton(!disableSubmitButton);
      let isSubmit = await dispatch(loginFun(values, history));
      setDisableSubmitButton(isSubmit.isSubmitButton);
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  return (
    <main>
      <section className="d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Form
                  className="mt-4 signUp_Form"
                  onSubmit={SignInFormik.handleSubmit}
                >
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type="email"
                        name="username"
                        value={SignInFormik.values.username}
                        onChange={(e) => {
                          SignInFormik.setFieldValue(
                            "username",
                            e.target.value
                          );
                        }}
                        placeholder="Enter email"
                      />
                      <Form.Control.Feedback type="invalid">
                        {SignInFormik?.errors?.username}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={SignInFormik.values.password}
                        onChange={(e) => {
                          SignInFormik.setFieldValue(
                            "password",
                            e.target.value
                          );
                        }}
                        placeholder="Password"
                      />
                      <InputGroup.Text
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <EyeIcon className="icon icon-xs  text-gray-600" />
                        ) : (
                          <EyeOffIcon className="icon icon-xs text-gray-600" />
                        )}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {SignInFormik?.errors?.password}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <div className="d-flex justify-content-end align-items-top mt-2">
                      <Card.Link
                        as={Link}
                        to={Routes.ForgotPassword.path}
                        className="small text-end"
                      >
                        Forgot Password?
                      </Card.Link>
                    </div>
                  </Form.Group>
                  <div className="d-grid">
                    <Button
                      variant="gray-800"
                      type="button"
                      disabled={disableSubmitButton}
                      onClick={() => {
                        SignInFormik.handleSubmit();
                      }}
                    >
                      {disableSubmitButton ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Sign in"
                      )}
                    </Button>
                  </div>
                </Form>
                {/* <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link
                      as={Link}
                      to={Routes.Signup.path}
                      className="fw-bold"
                    >
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
