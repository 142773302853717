import * as types from "./contants";

const initialState = {
  department: [],
  departmentData: [],
  designation: [],
  designationData: [],
};

export default function accountSetup(state = initialState, action) {
  switch (action.type) {
    case types.GET_DEPARTMENT: {
      return {
        ...state,
        department: action.payload,
        departmentData: action.payload.data,
      };
    }
    case types.EDIT_DEPARTMENT: {
      return {
        ...state,
        departmentData: state.departmentData?.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    }
    case types.GET_DESIGNATION: {
      return {
        ...state,
        designation: action.payload,
        designationData: action.payload.data,
      };
    }
    case types.EDIT_DESIGNATION: {
      return {
        ...state,
        designationData: state.designationData?.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    }
    default:
      return state;
  }
}
