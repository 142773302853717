import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
// core styles
import "./scss/volt.scss";

// vendor styles
import "leaflet/dist/leaflet.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datetime/css/react-datetime.css";
import Factory from "./store/index";
import HomePage from "./utils/RoutePage";
import ScrollToTop from "./components/ScrollToTop";
import Toastify from "pages/Notification";

const { store, persistor } = Factory;

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Toastify/>
          <ScrollToTop />
          <HomePage />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
