import * as types from "./contants";
import customAxios from "../../config/index";
import { toast } from "react-toastify";

// Get Slots Function
export const getSlotFun =
  (start, end, department, doctor, isAdmin) => async (dispatch) => {
    try {
      let URL = `&start=${start}&end=${end}`;
      if (isAdmin) {
        URL = `slots/?doctor=${doctor}&schedule=${department}` + URL;
      } else {
        URL = `slots/self/?` + URL;
      }
      const response = await customAxios.get(URL);
      dispatch({
        type: types.GET_SLOTS,
        payload: response.data,
      });
      return { isSubmitButton: false };
    } catch (error) {
      toast.error(error.response.data.detail);
      return { isSubmitButton: false };
    }
  };

export const assignSlotFun = (data, obj) => async (dispatch) => {
  try {
    const response = await customAxios.post("slots/assign/", data);
    toast.success(response.data.detail);
    dispatch(
      getSlotFun(obj.start, obj.end, obj.schedule, obj.doctor, obj.isAdmin)
    );
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

export const editSlotFun = (data, id) => async (dispatch) => {
  try {
    const response = await customAxios.patch(`slots/edit/${id}`, data);
    dispatch({
      type: types.EDIT_SLOTS,
      payload: response.data,
    });
    toast.success("Successfully Updated");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

export const delSlotFun = (id, obj) => async (dispatch) => {
  try {
    const response = await customAxios.delete(`slots/unassign/${id}`);
    dispatch(
      getSlotFun(obj.start, obj.end, obj.schedule, obj.doctor, obj.isAdmin)
    );
    toast.success("Successfully Unassigned");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};
