import React, { useState, useEffect } from "react";
import {
  HomeIcon,
  PlusIcon,
  MailIcon,
  LockClosedIcon,
} from "@heroicons/react/solid";
import {
  EyeIcon,
  TrashIcon,
  PencilIcon,
  EyeOffIcon,
} from "@heroicons/react/outline";
import {
  Breadcrumb,
  Button,
  Modal,
  Form,
  InputGroup,
  Spinner,
  Col,
  Row,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobleTable from "pages/tables/GlobleTable";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addDrFun, delDrFun, editDrFun, getDrFun } from "redux/doctor/action";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DoctorEdit from "./DoctorEdit";
import { customSelectStyles } from "utils/Global";
import ReactSelect from "react-select";
import _uniqueId from "lodash/uniqueId";

export default () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [showDefault, setShowDefault] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
    setShowConfirm(false);
    setShowDefaultEdit(false);
  };
  let doctors = useSelector((state) => state.doctors);
  const [showDefaultEdit, setShowDefaultEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [userID, setUserID] = useState("");

  const [userObj, setUserObj] = useState({});

  const [filtered, setFiltered] = useState("");

  const [options] = useState([
    {
      label: "All",
      value: "",
    },
    {
      label: "Yes",
      value: "true",
    },
    {
      label: "No",
      value: "false",
    },
  ]);

  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-gray",
      },
      buttonsStyling: false,
    })
  );

  const onClickAlert = async function (obj) {
    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "",
      text: "Are you sure do you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      setUserID(obj.id);
      setShowConfirm(true);
    }
  };

  const toggleChange = async (id, val) => {
    let payload = {
      isActive: val,
    };
    await dispatch(editDrFun(payload, id));
  };

  const columns = [
    { dataField: "id", text: "ID", hidden: true, csvExport: false },
    { dataField: "name", text: "Name", sort: true },
    { dataField: "email", text: "Email" },
    {
      dataField: "doctor.designation",
      text: "Designation",
      csvFormatter: (col) => (col ? col : "-"),
      formatter: (col) => (col ? col : "-"),
    },
    {
      dataField: "doctor.department",
      text: "Department",
      csvExport: true,
      csvFormatter: (col) => (col ? col : "-"),
      formatter: (col) => (col ? col : "-"),
    },
    {
      dataField: "phone",
      text: "Phone",
      csvFormatter: (cell, row) => {
        let value = "-";
        if (row?.phone) {
          value = `'${row?.phone}'`;
        }
        return value;
      },
      formatter: (col) => (col ? col : "-"),
    },
    {
      dataField: "gender",
      text: "Gender",
      csvFormatter: (col) => (col ? col : "-"),
      hidden: true,
      csvExport: true,
    },
    {
      dataField: "dob",
      text: "Date of Birth",
      hidden: true,
      csvExport: true,
      csvFormatter: (col) => (col ? col : "-"),
    },
    {
      dataField: "doctor.specialty",
      text: "Specialty",
      hidden: true,
      csvExport: true,
      csvFormatter: (col) => (col ? col : "-"),
    },
    {
      dataField: "doctor.qualification",
      text: "Qualification",
      csvFormatter: (col) => (col ? col : "-"),
      hidden: true,
      csvExport: true,
    },
    {
      dataField: "isAdmin",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <>
            <Form.Check
              type="switch"
              label=""
              defaultChecked={row.isActive}
              onChange={(e) => {
                toggleChange(row.id, e.target.checked);
              }}
            />
          </>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      csvExport: false,
      formatter: (cellContent, row) => {
        return (
          <>
            <EyeIcon
              id={_uniqueId("icon-")}
              className="icon icon-xs me-3"
              onClick={() => {
                if (row?.isAccountSetup) {
                  setUserObj(row);
                  setShowDefaultEdit(true);
                  setIsEdit(false);
                }
              }}
              style={{ cursor: row?.isAccountSetup ? "auto" : "no-drop" }}
            />
            <PencilIcon
              id={_uniqueId("icon-")}
              className="icon icon-xs me-3"
              onClick={() => {
                if (row?.isAccountSetup) {
                  setUserObj(row);
                  setShowDefaultEdit(true);
                  setIsEdit(true);
                }
              }}
              style={{ cursor: row?.isAccountSetup ? "auto" : "no-drop" }}
            />
            <TrashIcon
              id={_uniqueId("icon-")}
              className="icon icon-xs"
              onClick={() => {
                onClickAlert(row);
              }}
            />
          </>
        );
      },
    },
  ];

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const formikSchema = Yup.object().shape({
    email: Yup.string().trim().email().required("Email is required"),
  });

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: formikSchema,
    onSubmit: async (values, action) => {
      setDisableSubmitButton(!disableSubmitButton);
      let isSubmit = await dispatch(addDrFun(values, history));
      if (!isSubmit.isSubmitButton) {
        setDisableSubmitButton(isSubmit.isSubmitButton);
        handleClose();
        Formik.resetForm();
      }
    },
  });

  const formikSchemaa = Yup.object().shape({
    password: Yup.string()
      .trim()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
  });

  const Formikk = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
    },
    validationSchema: formikSchemaa,
    onSubmit: async (values, action) => {
      let updatedObj = Object.assign(values, { id: userID });
      setDisableSubmitButton(!disableSubmitButton);
      let isSubmit = await dispatch(delDrFun(updatedObj));
      if (!isSubmit.isSubmitButton) {
        setDisableSubmitButton(isSubmit.isSubmitButton);
        handleClose();
        Formikk.resetForm();
      }
    },
  });

  useEffect(() => {
    dispatch(getDrFun(1, "", filtered));
  }, [filtered]);

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-1">
        <div>
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Doctors</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h4>Doctors List</h4>
            </div>
          </div>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            variant="tertiary"
            size="sm"
            className="d-inline-flex align-items-center"
            onClick={() => {
              setShowDefault(true);
            }}
          >
            <PlusIcon className="icon icon-xs me-2" /> Add Doctor
          </Button>
        </div>
      </div>
      <Row>
        <Col xs={12} lg={3} md={4} sm={4} className="mt-2">
          <Form.Group id="select_doctor" className="mb-3">
            <Form.Label>Account Setup</Form.Label>
            <ReactSelect
              options={options}
              value={options.find((item) => item.value == filtered)}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#61DAFB",
                  primary: "#61DAFB",
                },
              })}
              styles={customSelectStyles}
              onChange={(arr) => {
                setFiltered(arr?.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <GlobleTable
        placeholder="Search doctor by Name or Email address"
        data={doctors?.data}
        columns={columns}
        totalEntries={doctors.allDoctor?.totalEntries}
        totalPage={doctors.allDoctor?.totalPages}
        tableType={"doctors"}
        currentPage={doctors.allDoctor?.currentPage}
      />
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Add Doctor</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={Formik.handleSubmit} className="mt-0 signUp_Form">
          <Modal.Body>
            <Form.Group id="email" className="mb-4 mt-0">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MailIcon className="icon icon-xs text-gray-600" />
                </InputGroup.Text>
                <Form.Control
                  autoFocus
                  type="email"
                  name="email"
                  value={Formik.values.email}
                  onChange={(e) => {
                    Formik.setFieldValue("email", e.target.value);
                  }}
                  placeholder="Doctor's Email"
                />
                <Form.Control.Feedback type="invalid">
                  {Formik?.errors?.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              disabled={disableSubmitButton}
            >
              {disableSubmitButton ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Invite"
              )}
            </Button>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal as={Modal.Dialog} centered show={showConfirm} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Delete Account</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={Formikk.handleSubmit} className="mt-0 signUp_Form">
          <Modal.Body>
            <p style={{ color: "red" }}>
              When you delete a doctor's account, all of the data associated
              with it - including slots and appointments will be permanently
              removed.
            </p>
            <Form.Group id="email" className="mb-4 mt-0">
              <Form.Label>
                Please enter your password for confirmation
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <LockClosedIcon className="icon icon-xs text-gray-600" />
                </InputGroup.Text>
                <Form.Control
                  autoFocus
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={Formikk.values.password}
                  onChange={(e) => {
                    Formikk.setFieldValue("password", e.target.value);
                  }}
                  placeholder="Password"
                />
                <InputGroup.Text
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? (
                    <EyeIcon className="icon icon-xs  text-gray-600" />
                  ) : (
                    <EyeOffIcon className="icon icon-xs text-gray-600" />
                  )}
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {Formikk?.errors?.password}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              disabled={disableSubmitButton}
            >
              {disableSubmitButton ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Delete"
              )}
            </Button>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit User */}
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefaultEdit}
        onHide={handleClose}
      >
        <DoctorEdit
          userObj={userObj}
          handleCloseEdit={handleClose}
          isEdit={isEdit}
        />
      </Modal>
    </>
  );
};
