import * as types from "./contants";

const initialState = {
  allScheduler: {},
  data: [],
  calendarSchedules: [],
};

export default function allScheduler(state = initialState, action) {
  switch (action.type) {
    case types.GET_SCH: {
      return {
        ...state,
        allScheduler: action.payload,
        data: action.payload.data,
      };
    }
    case types.GET_CAL_SCH: {
      return {
        ...state,
        calendarSchedules: action.payload.data,
      };
    }
    case types.EDIT_SCH: {
      return {
        ...state,
        data: state.data?.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    }
    case types.EDIT_SCH_FAIL: {
      return {
        ...state,
        data: state.data?.map((x) => {
          if (x.id == action.payload) {
            x.isActive = false;
          }
          return x;
        }),
      };
    }
    default:
      return state;
  }
}
