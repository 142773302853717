import React from "react";
import { Switch, Redirect, BrowserRouter } from "react-router-dom";
import { Routes } from "utils/routes";

import PublicRoute from "utils/PublicRoute";
import PrivateRoute from "utils/PrivateRoute";

// pages
import DashboardOverview from "pages/dashboard/DashboardOverview";
import DashboardTraffic from "pages/dashboard/DashboardTraffic";
import DashboardProductAnalysis from "pages/dashboard/DashboardProductAnalysis";
import Kanban from "pages/Kanban";
import Messages from "pages/Messages";
import SingleMessage from "pages/SingleMessage";
import Users from "pages/Users";
import Transactions from "pages/Transactions";
import Tasks from "pages/Tasks";
import Settings from "pages/settings/Profile_view";
import Calendar from "pages/scheduler/Calendar";
import MapPage from "pages/Map";
import Datatables from "pages/tables/Datatables";
import BootstrapTables from "pages/tables/BootstrapTables";
import Pricing from "pages/examples/Pricing";
import Billing from "pages/examples/Billing";
import Invoice from "pages/examples/Invoice";
import Signin from "pages/examples/Signin";
import Signup from "pages/examples/Signup";
import ForgotPassword from "pages/examples/ForgotPassword";
import ResetPassword from "pages/examples/ResetPassword";
import Lock from "pages/examples/Lock";
import Widgets from "pages/examples/Widgets";
import NotFoundPage from "pages/examples/NotFound";
import ServerError from "pages/examples/ServerError";

// documentation pages
import DocsOverview from "pages/documentation/DocsOverview";
import DocsDownload from "pages/documentation/DocsDownload";
import DocsQuickStart from "pages/documentation/DocsQuickStart";
import DocsLicense from "pages/documentation/DocsLicense";
import DocsFolderStructure from "pages/documentation/DocsFolderStructure";
import DocsBuild from "pages/documentation/DocsBuild";
import DocsChangelog from "pages/documentation/DocsChangelog";

// plugin pages
import PluginCharts from "pages/plugins/Charts";
import PluginCalendar from "pages/plugins/Calendar";
import PluginDatatable from "pages/plugins/Datatable";
import PluginMap from "pages/plugins/Map";
import PluginDropzone from "pages/plugins/Dropzone";
import PluginSweetAlert from "pages/plugins/SweetAlert";

import Accordion from "pages/components/Accordion";
import Alerts from "pages/components/Alerts";
import Badges from "pages/components/Badges";
import Breadcrumbs from "pages/components/Breadcrumbs";
import Buttons from "pages/components/Buttons";
import Forms from "pages/components/Forms";
import Modals from "pages/components/Modals";
import Navs from "pages/components/Navs";
import Navbars from "pages/components/Navbars";
import Pagination from "pages/components/Pagination";
import Popovers from "pages/components/Popovers";
import Progress from "pages/components/Progress";
import Tables from "pages/components/Tables";
import Tabs from "pages/components/Tabs";
import Tooltips from "pages/components/Tooltips";
import Toasts from "pages/components/Toasts";
import WidgetsComponent from "pages/components/Widgets";
import Listing from "pages/adminViews/Listing";
import AccountSetUp from "pages/settings/AccountSetUp";

import { useSelector } from "react-redux";
import Depart_Design from "pages/adminViews/Depart_Design";
import Schedular from "pages/adminViews/Schedular";
export default () => {
  const userDetail = useSelector((state) => state.auth);
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path={Routes.Signin.path} component={Signin} />
        {/* <PublicRoute exact path={Routes.Signup.path} component={Signup} /> */}
        <PublicRoute
          exact
          path={Routes.ForgotPassword.path}
          component={ForgotPassword}
        />
        <PublicRoute
          exact
          path={Routes.ResetPassword.path}
          component={ResetPassword}
        />
        <PublicRoute exact path={Routes.Lock.path} component={Lock} />
        <PublicRoute
          exact
          path={Routes.NotFound.path}
          component={NotFoundPage}
        />
        <PublicRoute
          exact
          path={Routes.ServerError.path}
          component={ServerError}
        />

        {/* Doctor Listing */}
        {userDetail.isAdmin && (
          <PrivateRoute
            exact
            path={Routes.ListingView.path}
            component={Listing}
          />
        )}
        {/* Schedular Listing */}
        {userDetail.isAdmin && (
          <PrivateRoute
            exact
            path={Routes.SchedularList.path}
            component={Schedular}
          />
        )}

        {/* Organization Listing */}
        {userDetail.isAdmin && (
          <PrivateRoute
            exact
            path={Routes.DepartDesign.path}
            component={Depart_Design}
          />
        )}

        <PrivateRoute
          exact
          path={Routes.AccountSetup.path}
          component={AccountSetUp}
        />

        {/* pages */}
        <PrivateRoute
          exact
          path={Routes.DashboardOverview.path}
          component={DashboardOverview}
        />
        <PrivateRoute
          exact
          path={Routes.DashboardTraffic.path}
          component={DashboardTraffic}
        />
        <PrivateRoute
          exact
          path={Routes.DashboardProductAnalysis.path}
          component={DashboardProductAnalysis}
        />
        <PrivateRoute exact path={Routes.Kanban.path} component={Kanban} />
        <PrivateRoute exact path={Routes.Messages.path} component={Messages} />
        <PrivateRoute
          exact
          path={Routes.SingleMessage.path}
          component={SingleMessage}
        />
        <PrivateRoute exact path={Routes.Users.path} component={Users} />
        <PrivateRoute
          exact
          path={Routes.Transactions.path}
          component={Transactions}
        />
        <PrivateRoute exact path={Routes.Tasks.path} component={Tasks} />
        <PrivateRoute exact path={Routes.Settings.path} component={Settings} />
        <PrivateRoute exact path={Routes.Calendar.path} component={Calendar} />
        <PrivateRoute exact path={Routes.Map.path} component={MapPage} />
        <PrivateRoute
          exact
          path={Routes.Datatables.path}
          component={Datatables}
        />
        <PrivateRoute
          exact
          path={Routes.BootstrapTables.path}
          component={BootstrapTables}
        />
        <PrivateRoute exact path={Routes.Pricing.path} component={Pricing} />
        <PrivateRoute exact path={Routes.Billing.path} component={Billing} />
        <PrivateRoute exact path={Routes.Invoice.path} component={Invoice} />

        {/* components */}
        <PrivateRoute
          exact
          path={Routes.Accordions.path}
          component={Accordion}
        />
        <PrivateRoute exact path={Routes.Alerts.path} component={Alerts} />
        <PrivateRoute exact path={Routes.Badges.path} component={Badges} />
        <PrivateRoute exact path={Routes.Widgets.path} component={Widgets} />
        <PrivateRoute
          exact
          path={Routes.Breadcrumbs.path}
          component={Breadcrumbs}
        />
        <PrivateRoute exact path={Routes.Buttons.path} component={Buttons} />
        <PrivateRoute exact path={Routes.Forms.path} component={Forms} />
        <PrivateRoute exact path={Routes.Modals.path} component={Modals} />
        <PrivateRoute exact path={Routes.Navs.path} component={Navs} />
        <PrivateRoute exact path={Routes.Navbars.path} component={Navbars} />
        <PrivateRoute
          exact
          path={Routes.Pagination.path}
          component={Pagination}
        />
        <PrivateRoute exact path={Routes.Popovers.path} component={Popovers} />
        <PrivateRoute exact path={Routes.Progress.path} component={Progress} />
        <PrivateRoute exact path={Routes.Tables.path} component={Tables} />
        <PrivateRoute exact path={Routes.Tabs.path} component={Tabs} />
        <PrivateRoute exact path={Routes.Tooltips.path} component={Tooltips} />
        <PrivateRoute exact path={Routes.Toasts.path} component={Toasts} />
        <PrivateRoute
          exact
          path={Routes.WidgetsComponent.path}
          component={WidgetsComponent}
        />

        {/* documentation */}
        <PrivateRoute
          exact
          path={Routes.DocsOverview.path}
          component={DocsOverview}
        />
        <PrivateRoute
          exact
          path={Routes.DocsDownload.path}
          component={DocsDownload}
        />
        <PrivateRoute
          exact
          path={Routes.DocsQuickStart.path}
          component={DocsQuickStart}
        />
        <PrivateRoute
          exact
          path={Routes.DocsLicense.path}
          component={DocsLicense}
        />
        <PrivateRoute
          exact
          path={Routes.DocsFolderStructure.path}
          component={DocsFolderStructure}
        />
        <PrivateRoute
          exact
          path={Routes.DocsBuild.path}
          component={DocsBuild}
        />
        <PrivateRoute
          exact
          path={Routes.DocsChangelog.path}
          component={DocsChangelog}
        />

        {/* plugins */}
        <PrivateRoute
          exact
          path={Routes.PluginCharts.path}
          component={PluginCharts}
        />
        <PrivateRoute
          exact
          path={Routes.PluginCalendar.path}
          component={PluginCalendar}
        />
        <PrivateRoute
          exact
          path={Routes.PluginDatatable.path}
          component={PluginDatatable}
        />
        <PrivateRoute
          exact
          path={Routes.PluginMap.path}
          component={PluginMap}
        />
        <PrivateRoute
          exact
          path={Routes.PluginDropzone.path}
          component={PluginDropzone}
        />
        <PrivateRoute
          exact
          path={Routes.PluginSweetAlert.path}
          component={PluginSweetAlert}
        />

        <Redirect to={Routes.NotFound.path} />
      </Switch>
    </BrowserRouter>
  );
};
