import React, { useState, useEffect } from "react";
import { Button, Modal, Form, InputGroup, Spinner } from "react-bootstrap";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import {
  UserIcon,
  MailIcon,
  CalendarIcon,
  AcademicCapIcon,
  ViewGridIcon,
} from "@heroicons/react/solid";
import { useFormik } from "formik";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { getDepartFun, getDesignFun } from "redux/departDesign/action";
import { useDispatch, useSelector } from "react-redux";
import { editDrFun } from "redux/doctor/action";

export default function DoctorEdit({ userObj, handleCloseEdit, isEdit }) {
  const dispatch = useDispatch();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  let options = useSelector((state) => state.accountSetup);

  const today = moment(new Date()).format("YYYY-MM-DD");
  const FormikSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Name is required")
      .min(3, "Too Short!")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(50, "Too Long!"),
    email: Yup.string().trim().email().required("Email is required"),
    phone: Yup.string().trim().required("Phone number is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date()
      .max(today, "Future date not allowed"),
    designation: Yup.string().required("Designation is required"),
    department: Yup.string().required("Department is required"),
    specialty: Yup.string()
      .trim()
      .required("Specialty is required")
      .min(3, "Too Short!")
      .max(50, "Too Long!"),
    qualification: Yup.string()
      .trim()
      .required("Qualification is required")
      .min(3, "Too Short!")
      .max(50, "Too Long!"),
  });

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userObj?.name?.length ? userObj?.name : "",
      email: userObj?.email?.length ? userObj?.email : "",
      phone: userObj?.phone?.length ? userObj?.phone : "",
      dob: userObj?.dob?.length ? userObj?.dob : today,
      gender: userObj?.gender?.length ? userObj?.gender : "",
      designation:
        userObj?.doctor && userObj?.doctor?.designation?.length
          ? userObj?.doctor?.designation
          : "",
      department:
        userObj?.doctor && userObj?.doctor?.department?.length
          ? userObj?.doctor?.department
          : "",
      specialty:
        userObj?.doctor && userObj?.doctor?.specialty?.length
          ? userObj?.doctor?.specialty
          : "",
      qualification:
        userObj?.doctor && userObj?.doctor?.qualification?.length
          ? userObj?.doctor?.qualification
          : "",
    },
    validationSchema: FormikSchema,
    onSubmit: async (values, action) => {
      let validPhone = isValidPhoneNumber(values.phone);
      if (validPhone === false) {
        Formik.errors["phone"] = "Invalid phone number";
        return false;
      }
      setDisableSubmitButton(!disableSubmitButton);
      let updatedObj = { ...values };
      updatedObj.dob = moment(updatedObj.dob).format("YYYY-MM-DD");
      let isSubmit = await dispatch(editDrFun(updatedObj, userObj?.id));
      handleCloseEdit();
      setDisableSubmitButton(isSubmit.isSubmitButton);
    },
  });

  useEffect(() => {
    if (isEdit) {
      dispatch(getDesignFun());
      dispatch(getDepartFun());
    }
  }, []);

  return (
    <>
      {isEdit ? (
        <>
          <Modal.Header>
            <Modal.Title className="h5">{userObj?.name}</Modal.Title>
            <Button
              variant="close"
              aria-label="Close"
              onClick={handleCloseEdit}
            />
          </Modal.Header>
          <Form
            className="mt-0 signUp_Form model"
            onSubmit={Formik.handleSubmit}
          >
            <Modal.Body>
              <Form.Group id="name" className="mb-3">
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <UserIcon className="icon icon-xs text-gray-600" />
                  </InputGroup.Text>
                  <Form.Control
                    autoFocus
                    name="name"
                    value={Formik.values.name}
                    onChange={Formik.handleChange}
                    type="text"
                    placeholder="Enter Full Name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {Formik?.errors?.name}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group id="email" className="mb-3">
                <Form.Label>Email</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <MailIcon className="icon icon-xs text-gray-600" />
                  </InputGroup.Text>
                  <Form.Control
                    autoFocus
                    type="email"
                    name="email"
                    value={Formik.values.email}
                    disabled
                    onChange={Formik.handleChange}
                    placeholder="Enter email"
                  />
                  <Form.Control.Feedback type="invalid">
                    {Formik?.errors?.email}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group id="birthday" className="mb-3">
                <Form.Label>Date of Birth</Form.Label>
                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={(date) => {
                    Formik.setFieldValue(
                      "dob",
                      moment(date).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <CalendarIcon className="icon icon-xs" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={Formik.values.dob}
                        placeholder="YYYY-MM-DD"
                        onFocus={openCalendar}
                        onChange={() => {}}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: "block" }}
                      >
                        {Formik?.errors?.dob}
                      </Form.Control.Feedback>
                    </InputGroup>
                  )}
                />
              </Form.Group>

              <Form.Group id="gender" className="mb-3">
                <Form.Label>Gender</Form.Label>
                <InputGroup>
                  <Form.Select
                    value={Formik.values.gender}
                    onChange={(e) => {
                      Formik.setFieldValue("gender", e.target.value);
                    }}
                    className="mb-0"
                    multiple={false}
                  >
                    <option defaultValue>Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {Formik?.errors?.gender}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group id="designation" className="mb-3">
                <Form.Label>Designation</Form.Label>
                <InputGroup>
                  <Form.Select
                    value={Formik.values.designation}
                    onChange={(e) => {
                      Formik.setFieldValue("designation", e.target.value);
                    }}
                    className="mb-0"
                    multiple={false}
                  >
                    <option defaultValue>Designation</option>
                    {options.designation.data?.map((val, index) => (
                      <option key={index + "_"} value={val.name}>
                        {val.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {Formik?.errors?.designation}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group id="department" className="mb-3">
                <Form.Label>Department</Form.Label>
                <InputGroup>
                  <Form.Select
                    value={Formik.values.department}
                    onChange={(e) => {
                      Formik.setFieldValue("department", e.target.value);
                    }}
                    className="mb-0"
                    multiple={false}
                  >
                    <option defaultValue>Department</option>
                    {options.department.data?.map((val, index) => (
                      <option key={index} value={val.name}>
                        {val.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {Formik?.errors?.department}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group id="specialty" className="mb-3">
                <Form.Label>Specialty</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <ViewGridIcon className="icon icon-xs text-gray-600" />
                  </InputGroup.Text>
                  <Form.Control
                    autoFocus
                    name="specialty"
                    value={Formik.values.specialty}
                    onChange={Formik.handleChange}
                    type="text"
                    placeholder="Enter Specialty"
                  />
                  <Form.Control.Feedback type="invalid">
                    {Formik?.errors?.specialty}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group id="qualification" className="mb-3">
                <Form.Label>Qualification</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <AcademicCapIcon className="icon icon-xs text-gray-600" />
                  </InputGroup.Text>
                  <Form.Control
                    autoFocus
                    name="qualification"
                    value={Formik.values.qualification}
                    onChange={Formik.handleChange}
                    type="text"
                    placeholder="Enter Qualification"
                  />
                  <Form.Control.Feedback type="invalid">
                    {Formik?.errors?.qualification}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group id="phone" className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <InputGroup>
                  <PhoneInput
                    country={"us"}
                    type="text"
                    label="Phone Number"
                    placeholder="Phone Number"
                    isinvalid
                    value={Formik.values.phone}
                    className="phoneNumberField form-control"
                    onChange={(phone) => {
                      Formik.setFieldValue("phone", phone);
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: "block" }}
                  >
                    {Formik?.errors?.phone}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-grid">
                <Button
                  variant="gray-800"
                  type="submit"
                  onClick={Formik.handleSubmit}
                  disabled={disableSubmitButton}
                >
                  {disableSubmitButton ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
              <Button
                variant="link"
                className="text-gray ms-auto"
                onClick={handleCloseEdit}
              >
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </>
      ) : (
        <>
          <Modal.Header>
            <Modal.Title className="h5">{userObj?.name}</Modal.Title>
            <Button
              variant="close"
              aria-label="Close"
              onClick={handleCloseEdit}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-2 small pe-3 text-gray-400">
              <b className="text-gray-800">Email: </b> {userObj?.email}
            </p>
            <p className="mb-2 small pe-3 text-gray-400">
              <b className="text-gray-800">Gender: </b>{" "}
              {userObj?.gender?.toUpperCase()}
            </p>
            <p className="mb-2 small pe-3 text-gray-400">
              <b className="text-gray-800">Phone: </b> {userObj?.phone}
            </p>
            <p className="mb-2 small pe-3 text-gray-400">
              <b className="text-gray-800">Date of birth: </b> {userObj?.dob}
            </p>
            <p className="mb-2 small pe-3 text-gray-400">
              <b className="text-gray-800">Department: </b>{" "}
              {userObj?.doctor?.department}
            </p>
            <p className="mb-2 small pe-3 text-gray-400">
              <b className="text-gray-800">Designation: </b>{" "}
              {userObj?.doctor?.designation}
            </p>
            <p className="mb-2 small pe-3 text-gray-400">
              <b className="text-gray-800">Qualification: </b>{" "}
              {userObj?.doctor?.qualification}
            </p>
            <p className="mb-2 small pe-3 text-gray-400">
              <b className="text-gray-800">Specialty: </b>{" "}
              {userObj.doctor?.specialty}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleCloseEdit}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      )}
    </>
  );
}
