import React, { useState, useEffect } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
// import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";
import Sidebar from "components/Sidebar";
import Topbar from "components/Topbar";
// import Footer from "components/Footer";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event("resize"));
    }, 10);
    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  };

  const localStorageIsContracted = () => {
    return localStorage.getItem("sidebarContracted") === "false" ? false : true;
  };

  // const localStorageIsSettingsVisible = () => {
  //   return localStorage.getItem("settingsVisible") === "false" ? false : true;
  // };

  const [contracted, setContracted] = useState(localStorageIsContracted());

  const [contractSidebar, setContractSidebar] = useState(
    localStorageIsContracted()
  );

  // const [showSettings, setShowSettings] = useState(
  //   localStorageIsSettingsVisible
  // );

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    setContracted(!contracted);
    setContractSidebar(!contracted);
    localStorage.setItem("sidebarContracted", !contracted);
    resize();
  };

  // const toggleSettings = () => {
  //   setShowSettings(!showSettings);
  //   localStorage.setItem("settingsVisible", !showSettings);
  // };

  useEffect(() => {
    localStorage.setItem("sidebarContracted", false);
  }, []);

  let history = useHistory();
  let getLocation = useLocation();
  const userDetail = useSelector((state) => state.auth);

  useEffect(() => {
    if (!userDetail.token) {
      return history.push("/");
    }
    if(userDetail.isDoctor && !userDetail.isAccountSetup){
      return history.push("/accountSetup");
    }
  }, [userDetail]);

  if (userDetail.token) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <>
            {getLocation.pathname == "/accountSetup" ? (
              <Component {...props} />
            ) : (
              <>
                <Sidebar
                  contracted={contractSidebar}
                  onMouseEnter={toggleMouseOver}
                  onMouseLeave={toggleMouseOver}
                />

                <main className="content mb-5">
                  <Topbar toggleContracted={toggleContracted} />
                  <Component {...props} />
                </main>
              </>
            )}
          </>
        )}
      />
    );
  } else {
    return <Redirect to="/" />;
  }
};

export default PrivateRoute;
