import React, { useRef, useState, useEffect } from "react";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import interactionPlugin from "@fullcalendar/interaction";
import Datetime from "react-datetime";
import { CalendarIcon } from "@heroicons/react/solid";
import {
  Card,
  Breadcrumb,
  Form,
  Modal,
  Col,
  Button,
  Row,
  OverlayTrigger,
  Tooltip,
  InputGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { EventModal } from "./EventModal";
import { HomeIcon } from "@heroicons/react/solid";
import { delSlotFun, getSlotFun } from "redux/calendar/action";
import { getDrByIdFun, getDrFun } from "redux/doctor/action";
import { toast } from "react-toastify";
import { getDepartFun } from "redux/departDesign/action";
import { customSelectStyles, SlotsIteration } from "utils/Global";
import ReactSelect from "react-select";
import { getSchedulerFun } from "redux/scheduler/action";
import factory from "../../store/index";
import { useLocation } from "react-router-dom";
const { store } = factory;

const SwalWithBootstrapButtons = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-3",
      cancelButton: "btn btn-gray",
    },
    buttonsStyling: false,
  })
);

export default () => {
  const defaultModalProps = {
    title: "",
    doctor: null,
    id: "",
    start: null,
    end: null,
    days: [],
  };
  const dispatch = useDispatch();
  const location = useLocation();
  let auth = useSelector((state) => state.auth);
  let slots = useSelector((state) => state.slots.slots);
  let doctors = useSelector((state) => state.doctors.data);
  let user = useSelector((state) => state.doctors.user);
  let schedules = useSelector((state) => state.scheduler.calendarSchedules);
  schedules = schedules?.map(({ name: label, id: value, ...rest }) => ({
    label,
    value,
    ...rest,
  }));

  let departmentsOptions = useSelector(
    (state) => state.accountSetup.departmentData
  );
  departmentsOptions = departmentsOptions.map(
    ({ name: label, id: value, ...rest }) => ({
      label,
      value,
      ...rest,
    })
  );
  const [drArr, setDrArr] = useState([]);
  const [mainDrArr, setMainDrArr] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [selectedDoctors, setSelectedDoctors] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedSchedules, setSelectedSchedules] = useState("");
  const [schedulesOpt, setSchedulesOpt] = useState([]);
  const [allSlots, setAllSlots] = useState([]);
  const calendarRef = useRef();
  const currentDate = moment().format("YYYY-MM-DD");

  const [startMonth, setStartMonth] = useState(
    moment(calendarRef?.current?.getApi()?.getCurrentData()?.viewTitle)
      .startOf("month")
      .format("YYYY-MM-DDTHH:mm:ssZ")
  );
  const [lastMonth, setLastMonth] = useState(
    moment(calendarRef?.current?.getApi()?.getCurrentData()?.viewTitle)
      .endOf("month")
      .format("YYYY-MM-DDTHH:mm:ssZ")
  );

  useEffect(() => {
    if (doctors) {
      let arr = doctors.map((val) => ({
        value: val.id,
        label: val?.name,
      }));
      setDrArr(arr);
      setMainDrArr(() => [
        {
          value: "",
          label: "All",
        },
        ...arr,
      ]);
    }
  }, [doctors]);

  useEffect(() => {
    let state = location.state;
    if (state) {
      let depVal = state.obj.department;
      setSelectedDepartment(depVal);
    }
  }, [location]);

  const equalsCheck = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  useEffect(() => {
    let data = [];
    if (slots) {
      data = SlotsIteration(slots);
    }
    setAllSlots([...data]);
  }, [slots]);

  useEffect(() => {
    if (auth?.isAdmin) {
      let check = equalsCheck(schedulesOpt, schedules);
      if (!check) {
        setSchedulesOpt([...schedules]);
        let scheduleId = location?.state?.obj?.id;
        const filtered = schedules.filter((val) => val?.value === scheduleId);
        if (filtered.length) {
          setSelectedSchedules(filtered[0]);
        }
      }
    }
  }, [schedules]);

  useEffect(() => {
    if (auth.isAdmin) {
      dispatch(getDrFun());
    }
    dispatch(getDepartFun());
  }, []);

  useEffect(() => {
    store.dispatch({
      type: "GET_CAL_SCH",
      payload: {
        data: [],
      },
    });
    store.dispatch({
      type: "GET_SLOTS",
      payload: [],
    });
  }, []);

  const dateCal = (calender) => {
    let view = calender.current.getApi().getCurrentData()?.currentViewType;
    let type =
      view === "dayGridMonth"
        ? "month"
        : view === "timeGridDay"
        ? "day"
        : view === "timeGridWeek"
        ? "week"
        : "";
    let date =
      type === "week"
        ? calender.current.getApi().getCurrentData().currentDate
        : calender.current.getApi().getCurrentData()?.viewTitle;
    setStartMonth(moment(date).startOf(type).format("YYYY-MM-DDTHH:mm:ssZ"));
    setLastMonth(moment(date).endOf(type).format("YYYY-MM-DDTHH:mm:ssZ"));
  };

  useEffect(() => {
    if (selectedSchedules) {
      dispatch(
        getSlotFun(
          startMonth,
          lastMonth,
          selectedSchedules?.value,
          selectedDoctors,
          auth?.isAdmin
        )
      );
    }
    if (!auth?.isAdmin) {
      dispatch(getSlotFun(startMonth, lastMonth, "", "", auth?.isAdmin));
    }
  }, [startMonth, lastMonth, selectedDoctors, selectedSchedules]);

  useEffect(() => {
    if (selectedDepartment) {
      store.dispatch({
        type: "GET_SLOTS",
        payload: [],
      });
      setSelectedSchedules("");
      setSelectedDoctors("");
      dispatch(getSchedulerFun("", "", selectedDepartment));
    }
  }, [selectedDepartment]);

  const onDateClick = (props) => {
    if (!auth?.isAdmin) {
      return 0;
    }
    if (!selectedSchedules) {
      toast.error("Please select any schedule");
      return 0;
    }
    let propsDate = moment(props.date);
    let now = moment();
    if (
      (propsDate >= now || moment().isSame(props.date, "day")) &&
      propsDate <= moment(selectedSchedules.endDate)
    ) {
      const date = moment(props.date).format("YYYY-MM-DD");
      const endDate = moment(props.date).endOf("day").add(0, "day").toDate();
      const scheduleStart = moment().format("YYYY-MM-DD");
      const scheduleend = moment(selectedSchedules?.endDate)
        .endOf("day")
        .add(0, "day")
        .toDate();
      setModalProps({
        ...defaultModalProps,
        start: date,
        end: endDate,
        scheduleStart: scheduleStart,
        scheduleEnd: scheduleend,
      });
      setShowAddModal(true);
    } else {
      toast.error("Please select the valid date for slot assigning.");
    }
  };

  const onMonthEvent = () => {
    calendarRef.current.getApi().changeView("dayGridMonth");
    dateCal(calendarRef);
  };

  // const onWeekEvent = () => {
  //   calendarRef.current.getApi().changeView("timeGridWeek");
  //   dateCal(calendarRef);
  // };

  const onPrevEvent = () => {
    calendarRef.current.getApi().prev();
    dateCal(calendarRef);
  };

  const onNextEvent = () => {
    calendarRef.current.getApi().next();
    dateCal(calendarRef);
  };

  const onEventClick = async (props) => {
    const {
      event: { id },
    } = props;
    const object = slots.find((obj) => obj.id == id);
    if (auth?.isAdmin) {
      await dispatch(getDrByIdFun(object.doctor));
    }
    const scheduleStart = moment(object?.start).format("YYYY-MM-DD");
    const scheduleend = moment(selectedSchedules?.endDate)
      .endOf("day")
      .add(0, "day")
      .toDate();
    setModalProps({
      ...object,
      scheduleStart: scheduleStart,
      scheduleEnd: scheduleend,
    });
    setShowEditModal(true);
  };

  const onEventDelete = async (id, obj) => {
    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: "Are you sure you want to unassign this slot?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      setShowEditModal(false);
      setModalProps(defaultModalProps);
      dispatch(delSlotFun(id, obj));
    }
  };

  const handleClose = () => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowDateModal(false);
  };

  const gotoDate = (date) => {
    setStartDate(date);
    calendarRef.current.getApi().gotoDate(new Date(date));
    dateCal(calendarRef);
    setShowDateModal(false);
  };

  const [startDate, setStartDate] = useState(new Date());

  const EventDetail = ({ event }) => {
    const object = slots?.find((obj) => obj.id == event.id);
    const startTime = moment(object?.start);
    const endTime = moment(object?.end);
    const duration = moment.duration(endTime.diff(startTime));
    const content = (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip className="m-0">
            <p className="p-0 m-0 fontSizeSm">{event.title}</p>
            <p className="p-0 m-0 fontSizeSm">
              {moment(object?.start).format("hh:mm A")} {" - "}{" "}
              {moment(object?.end).format("hh:mm A")}
            </p>
            <p className="p-0 m-0 fontSizeSm">
              Duration: {duration.hours()} hours, {duration.minutes()} minutes
            </p>
          </Tooltip>
        }
      >
        <span>
          <p className="p-0 m-0 fontSizeSm">{event.title}</p>
          <p className="p-0 m-0 fontSizeSm">
            {moment(object?.start).format("hh:mm A")} {" - "}{" "}
            {moment(object?.end).format("hh:mm A")}
            {/* <p className="p-0 m-0 fontSizeSm">
              {duration.hours()}h {duration.minutes()}min
            </p> */}
          </p>
        </span>
      </OverlayTrigger>
    );
    return content;
  };

  return (
    <>
      {showEditModal ? (
        <EventModal
          {...modalProps}
          edit={true}
          userObj={user}
          show={showEditModal}
          doctorsArr={drArr}
          onDelete={onEventDelete}
          schedules={selectedSchedules.value}
          onHide={handleClose}
          calenderUpdate={{
            start: startMonth,
            end: lastMonth,
            doctor: selectedDoctors,
            isAdmin: auth.isAdmin,
            schedule: selectedSchedules.value,
          }}
        />
      ) : null}

      {showAddModal ? (
        <EventModal
          {...modalProps}
          show={showAddModal}
          doctorsArr={drArr}
          onHide={handleClose}
          schedules={selectedSchedules.value}
          calenderUpdate={{
            start: startMonth,
            end: lastMonth,
            doctor: selectedDoctors,
            isAdmin: auth.isAdmin,
            schedule: selectedSchedules.value,
          }}
        />
      ) : null}

      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-1">
        <div className="d-block">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Calendar</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Calendar</h4>
        </div>
      </div>
      <Row>
        {auth.isAdmin && (
          <>
            <Col xs={12} lg={3} md={4} sm={4} className="mt-2">
              <Form.Group id="select_doctor" className="mb-3">
                {/* <Form.Label>Select Department</Form.Label> */}
                <ReactSelect
                  placeholder="Select Department"
                  isSearchable={true}
                  options={departmentsOptions}
                  value={departmentsOptions.find(
                    (item) => item.value == selectedDepartment
                  )}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#61DAFB",
                      primary: "#61DAFB",
                    },
                  })}
                  styles={customSelectStyles}
                  onChange={(arr) => {
                    setSelectedDepartment(arr?.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={3} md={4} sm={4} className="mt-2">
              <Form.Group id="select_doctor" className="mb-3">
                {/* <Form.Label>Select Schedule</Form.Label> */}
                <ReactSelect
                  placeholder="Select Schedule"
                  isSearchable={true}
                  options={schedules}
                  value={selectedSchedules}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#61DAFB",
                      primary: "#61DAFB",
                    },
                  })}
                  styles={customSelectStyles}
                  onChange={(arr) => {
                    setSelectedSchedules(arr);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={3} md={4} sm={4} className="mt-2">
              <Form.Group id="select_doctor" className="mb-3">
                {/* <Form.Label>Select Doctor</Form.Label> */}
                <ReactSelect
                  placeholder="Select doctor"
                  isSearchable={true}
                  options={mainDrArr}
                  value={
                    selectedDoctors
                      ? mainDrArr.find((item) => item.value == selectedDoctors)
                      : null
                  }
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#61DAFB",
                      primary: "#61DAFB",
                    },
                  })}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={12} md={12} sm={12} className="mt-2">
              <div className="d-flex justify-content-between">
                <span className="doted-text">
                  {selectedSchedules
                    ? `${selectedSchedules?.label} starts from ${moment(
                        selectedSchedules.startDate
                      ).format("DD-MMMM-YYYY")} and ends on ${moment(
                        selectedSchedules.endDate
                      ).format("DD-MMMM-YYYY")}`
                    : "Please select the department and schedule from the drop-down menu."}
                </span>
                {selectedSchedules && (
                  <>
                    <span className="me-5">
                      <b>Status: </b>
                      <span
                        className={`dot ${
                          selectedSchedules?.isActive ? "dot-green" : "dot-red"
                        }`}
                      ></span>{" "}
                      {selectedSchedules?.isActive ? " Active" : " Inactive"}
                    </span>
                  </>
                )}
              </div>
            </Col>
          </>
        )}
      </Row>

      <Card className="border-0 shadow">
        <Card.Body>
          <FullCalendar
            isDraggable={false}
            ref={calendarRef}
            events={allSlots}
            eventContent={EventDetail}
            eventColor="#002F6C"
            themeSystem="bootstrap"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              bootstrapPlugin,
              interactionPlugin,
            ]}
            nowIndicator={true}
            allDaySlot={false}
            initialView="dayGridMonth"
            buttonText={{
              prev: "Prev",
              next: "Next",
              // month: "Month",
              // week: "Week",
            }}
            headerToolbar={{
              left: "prevButton nextButton",
              center: "title",
              right: "calenderButton",
            }}
            customButtons={{
              // monthButton: {
              //   text: "Month",
              //   click: function () {
              //     onMonthEvent();
              //   },
              // },
              // weekButton: {
              //   text: "Week",
              //   click: function () {
              //     onWeekEvent();
              //   },
              // },

              prevButton: {
                text: "Prev",
                click: function () {
                  onPrevEvent();
                },
              },

              nextButton: {
                text: "Next",
                click: function () {
                  onNextEvent();
                },
              },

              calenderButton: {
                text: "Goto",
                click: function () {
                  setShowDateModal(true);
                },
              },
            }}
            goToDate={startDate}
            bootstrapFontAwesome={false}
            initialDate={currentDate}
            eventClick={onEventClick}
            dateClick={onDateClick}
          />
        </Card.Body>
      </Card>

      <Modal
        as={Modal.Dialog}
        centered
        show={showDateModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Goto Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group id="startDate">
            <Form.Label>Select Date</Form.Label>
            <Datetime
              timeFormat={false}
              closeOnSelect={true}
              dateFormat="YYYY-MM"
              onChange={(date) => {
                gotoDate(date);
              }}
              renderInput={(props, openCalendar) => (
                <InputGroup>
                  <InputGroup.Text>
                    <CalendarIcon className="icon icon-xs" />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="YYYY-MM"
                    value={moment(startDate).format("YYYY-MM")}
                    onFocus={openCalendar}
                    onChange={() => {}}
                  />
                </InputGroup>
              )}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
