const moment = require("moment");

export const CapitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};
export const weekArr = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const GetDays = (first, last) => {
  const startDate = moment(first);
  const endDate = moment(last);
  endDate.add(1, "day");
  const daysOfWeek = [];
  let i = 0;
  while (i < 7 && startDate < endDate) {
    daysOfWeek.push(moment(startDate.toDate()).format("dddd")?.toLowerCase());
    startDate.add(1, "day");
    i++;
  }
  return daysOfWeek;
};

export const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: ".5rem",
  }),

  multiValue: (provided) => ({
    ...provided,
    borderRadius: ".5rem",
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: ".5rem",
  }),

  placeholder: (provided) => ({
    ...provided,
    borderRadius: ".5rem",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...provided,
      opacity,
      transition,
    };
  },
};

export const SlotsIteration = (slots) => {
  let updatedData = [];
  let data = slots?.map((val) => {
    let start_Date = moment(val.start);
    let end_Date = moment(val.end);
    let now = start_Date.clone();
    let dates = [];
    while (now.isSameOrBefore(end_Date)) {
      dates.push(now.format("YYYY-MM-DD"));
      now.add(1, "days");
    }
    dates.map((v) => {
      let dayCheck = moment(v).format("dddd").toLowerCase();
      if (val?.days.includes(dayCheck)) {
        let colorID = updatedData?.find((arrItem) => arrItem?.id == val.id);
        updatedData.push({
          ...val,
          end: v,
          start: v,
          backgroundColor: colorID
            ? colorID.backgroundColor
            : `#${Math.floor(Math.random() * 14777415).toString(16)}`,
        });
      }
    });
  });
  return updatedData;
};
