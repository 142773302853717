import * as types from "./contants";

const initialState = {
  slots: [],
};

export default function slotReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_SLOTS: {
      return {
        ...state,
        slots: action.payload,
      };
    }
    case types.EDIT_SLOTS: {
      return {
        ...state,
        slots: state.slots?.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    }
    default:
      return state;
  }
}
