import React, { useEffect } from "react";
import { Redirect, Route, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ component: Component, restricted, ...props }) => {
  const userDetail = useSelector((state) => state.auth.token);

  let history = useHistory();
  let params = useParams();
  useEffect(() => {
    let address = window.location.pathname.toString();
    if (!userDetail) {
      if (address.includes("/reset-password")) {
        return true;
      } else {
        return history.push("/");
      }
    }
  }, [userDetail]);
  return (
    <Route
      {...props}
      render={(props) =>
        userDetail ? <Redirect to="/overview" /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
