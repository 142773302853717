import * as types from "./contants";
import customAxios from "../../config/index";
import { toast } from "react-toastify";

// // Add scheduler Function
export const addSchedulerFun = (data) => async (dispatch) => {
  try {
    const response = await customAxios.post("schedules/create/", data);
    toast.success(response.data.detail);
    dispatch(getSchedulerFun(1));
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Get scheduler Function
export const getSchedulerFun =
  (page, search, department) => async (dispatch) => {
    try {
      let URL = `schedules/`;
      if (page) {
        URL = URL + `?page=${page}`;
      }
      if (search) {
        URL = URL + `&search=${search}`;
      }
      if (department) {
        URL = URL + `?department=${department}`;
      }
      const response = await customAxios.get(URL);
      dispatch({
        type: department ? types.GET_CAL_SCH : types.GET_SCH,
        payload: response.data,
      });
    } catch (error) {
      toast.error(error?.response?.data?.detail);
      return { isSubmitButton: false };
    }
  };

// Get scheduler Function
export const getFilteredSchedulerFun =
  (page, search, department) => async (dispatch) => {
    try {
      let URL = `schedules/`;
      if (page) {
        URL = URL + `?page=${page}`;
      }
      if (search) {
        URL = URL + `&search=${search}`;
      }
      if (department) {
        URL = URL + `&department=${department}`;
      }
      const response = await customAxios.get(URL);
      dispatch({
        type: types.GET_SCH,
        payload: response.data,
      });
    } catch (error) {
      toast.error(error?.response?.data?.detail);
      return { isSubmitButton: false };
    }
  };

// Edit Scheduler Function
export const editSchedulerFun = (data, id) => async (dispatch) => {
  try {
    const response = await customAxios.patch(`schedules/edit/${id}`, data);
    dispatch({
      type: types.EDIT_SCH,
      payload: response.data,
    });
    toast.success("Successfully Updated");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    dispatch({
      type: types.EDIT_SCH_FAIL,
      payload: id,
    });
    return { isSubmitButton: false };
  }
};

// Delete Scheduler Function
export const delSchedulerFun = (id) => async (dispatch) => {
  try {
    const response = await customAxios.delete(`schedules/delete/${id}`);
    dispatch(getSchedulerFun(1));
    toast.success(response.data.detail);
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};
