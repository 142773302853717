import { combineReducers } from "redux";
import slotReducer from "redux/calendar/reducer";
import accountSetup from "redux/departDesign/reducer";
import allDoctors from "redux/doctor/reducer";
import allScheduler from "redux/scheduler/reducer";
import authReducer from "../redux/auth/reducer";

const combinedReducer = combineReducers({
  auth: authReducer,
  accountSetup: accountSetup,
  doctors: allDoctors,
  slots: slotReducer,
  scheduler: allScheduler,
});

const rootReducer = (state, action) => {
  if (action.type === "AUTH_LOGOUT") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
