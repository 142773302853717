import React, { useState, useEffect } from "react";
import { HomeIcon, PlusIcon, MailIcon } from "@heroicons/react/solid";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import {
  Breadcrumb,
  Button,
  Modal,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { Col, Row, Card, Nav, Tab } from "react-bootstrap";
import { CapitalizeFirstLetter } from "utils/Global";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobleTable from "pages/tables/GlobleTable";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  addDepartFun,
  addDesigntFun,
  delDepartFun,
  delDesigntFun,
  editDepartFun,
  editDesigntFun,
  getDepartFun,
  getDesignFun,
} from "redux/departDesign/action";
import withReactContent from "sweetalert2-react-content";
import _uniqueId from "lodash/uniqueId";

export default () => {
  let object = ["department", "designation"];
  const dispatch = useDispatch();
  const [showDefault, setShowDefault] = useState(false);
  const [tabValue, setTabValue] = useState("department");
  const handleClose = () => setShowDefault(false);
  const [isEdit, setIsEdit] = useState(false);
  let options = useSelector((state) => state.accountSetup);
  const [getObj, setObj] = useState({});

  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-gray",
      },
      buttonsStyling: false,
    })
  );

  const onClickAlert = async function (obj) {
    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "",
      text: "Are you sure do you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      tabValue === "department"
        ? await dispatch(delDepartFun(obj.id))
        : await dispatch(delDesigntFun(obj.id));
    }
  };

  const columns = [
    { dataField: "id", text: "ID", hidden: true, csvExport: false },
    { dataField: "name", text: "Name", sort: true },
    {
      dataField: "action",
      text: "Action",
      csvExport: false,
      formatter: (cellContent, row) => {
        return (
          <>
            <PencilIcon
              id={_uniqueId("icon-")}
              className="icon icon-xs me-3"
              onClick={() => {
                setObj(row);
                setShowDefault(true);
                setIsEdit(true);
              }}
            />
            <TrashIcon
              id={_uniqueId("icon-")}
              className="icon icon-xs"
              onClick={() => {
                onClickAlert(row);
              }}
            />
          </>
        );
      },
    },
  ];

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const formikSchema = Yup.object().shape({
    name: Yup.string().trim().required("Field is required"),
  });

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: getObj && getObj?.name ? getObj?.name : "",
    },
    validationSchema: formikSchema,
    onSubmit: async (values, action) => {
      setDisableSubmitButton(!disableSubmitButton);
      let isSubmit =
        tabValue === "department"
          ? isEdit === false // check
            ? await dispatch(addDepartFun(values)) // Add department
            : await dispatch(editDepartFun(values, getObj?.id)) // Edit department
          : isEdit === false // check
          ? await dispatch(addDesigntFun(values)) // Add designations
          : await dispatch(editDesigntFun(values, getObj?.id)); // Edit designations

      if (!isSubmit.isSubmitButton) {
        setDisableSubmitButton(isSubmit.isSubmitButton);
        handleClose();
        setObj({});
        Formik.resetForm();
      }
    },
  });

  useEffect(() => {
    dispatch(getDepartFun(1));
  }, []);

  const handleValue = (val) => {
    setTabValue(val);
    val == "department" ? dispatch(getDepartFun(1)) : dispatch(getDesignFun(1));
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-1">
        <div>
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Organization</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h4>Departments & Designations List</h4>
            </div>
          </div>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            variant="tertiary"
            size="sm"
            className="d-inline-flex align-items-center"
            onClick={() => {
              setIsEdit(false);
              setShowDefault(true);
              setObj({});
            }}
          >
            <PlusIcon className="icon icon-xs me-2" /> Add{" "}
            {CapitalizeFirstLetter(tabValue)}
          </Button>
        </div>
      </div>
      <Card className="p-3">
        <Tab.Container defaultActiveKey="department">
          <Row>
            <Col lg={12}>
              <Nav className="nav-tabs">
                {object.map((val, index) => {
                  return (
                    <Nav.Item key={index}>
                      <Nav.Link
                        eventKey={val}
                        onClick={() => {
                          handleValue(val);
                        }}
                        className="mb-sm-3 mb-md-0"
                      >
                        {CapitalizeFirstLetter(val)}
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Col>
            <Col lg={12}>
              <Tab.Content>
                {object.map((val, index) => {
                  return (
                    <Tab.Pane key={index} eventKey={val} className="py-4">
                      <GlobleTable
                        placeholder={
                          val === "department"
                            ? "Search Department"
                            : "Search Designation"
                        }
                        data={options[val + "Data"]}
                        columns={columns}
                        totalEntries={options[val]?.totalEntries}
                        totalPage={options[val].totalPages}
                        tableType={val}
                        currentPage={options[val]?.currentPage}
                      />
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Card>

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">
            {isEdit ? "Edit" : "Add"} {CapitalizeFirstLetter(tabValue)}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={Formik.handleSubmit} className="mt-0 signUp_Form">
          <Modal.Body>
            <Form.Group id="name" className="mb-4 mt-0">
              <Form.Label>Name</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MailIcon className="icon icon-xs text-gray-600" />
                </InputGroup.Text>
                <Form.Control
                  autoFocus
                  type="text"
                  name="name"
                  value={Formik.values.name}
                  onChange={(e) => {
                    Formik.setFieldValue("name", e.target.value);
                  }}
                  placeholder={
                    isEdit
                      ? `Update ${CapitalizeFirstLetter(tabValue)}`
                      : `Enter ${CapitalizeFirstLetter(tabValue)}`
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {Formik?.errors?.name}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              disabled={disableSubmitButton}
            >
              {disableSubmitButton ? (
                <Spinner animation="border" size="sm" />
              ) : isEdit ? (
                "Update"
              ) : (
                "Save"
              )}
            </Button>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
