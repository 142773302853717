import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { ClockIcon, CalendarIcon } from "@heroicons/react/solid";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Col,
  Row,
  Form,
  Modal,
  Button,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import {
  CapitalizeFirstLetter,
  customSelectStyles,
  GetDays,
  weekArr,
} from "utils/Global";
import { assignSlotFun, editSlotFun } from "redux/calendar/action";
export const EventModal = (props) => {
  const {
    show = false,
    edit = false,
    id,
    doctor = null,
    days = [],
    type = "daily",
  } = props;
  const dispatch = useDispatch();
  let auth = useSelector((state) => state.auth);
  const [doctorsOptions] = useState(props.doctorsArr);
  const [userObj] = useState(props?.userObj);
  const [isDays, setIsDays] = useState([]);
  const [selectedDays, setSelectedDays] = useState([...days]);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  useEffect(() => {
    if (doctor !== null) {
      let obj = doctorsOptions.find((item) => item.value == doctor);
      setSelectedDoctors((selectedDoctors) => [...selectedDoctors, obj]);
    } else {
      setSelectedDoctors([]);
    }
  }, [doctor]);

  const onDelete = () =>
    edit && props.onDelete && props.onDelete(id, props.calenderUpdate);
  const onHide = () => props.onHide && props.onHide();

  const FormikSchema = Yup.object().shape({
    doctor: Yup.array().min(1).required("Doctor is required"),
    type: Yup.string().required("Please select any type"),
    days:
      Yup.ref("type") === "custom"
        ? Yup.array().min(1).required("Days are required")
        : Yup.array().min(1),
    start_date: Yup.date(),
    end_date: Yup.date().min(
      Yup.ref("start_date"),
      "End date can't be before start date"
    ),
    start_time: Yup.string().required(),
    end_time: Yup.string()
      .required()
      .test("", "Shift can't be less than an hour", function (value) {
        const { start_time, end_date, start_date } = this.parent;
        if (
          moment(start_date).format("DD-MMMM-YYYY") ===
          moment(end_date).format("DD-MMMM-YYYY")
        ) {
          return moment(value, "HH:mm a").isSameOrAfter(
            moment(start_time, "HH:mm a").add(1, "hours")
          );
        } else {
          return true;
        }
      }),
  });
  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      doctor: [],
      start_date: moment(props.start).format("DD-MMMM-YYYY"),
      end_date: moment(props.end).format("DD-MMMM-YYYY"),
      start_time: moment(props.start).format("hh:mm a"),
      end_time: moment(props.end).format("hh:mm a"),
      days: [],
      type: props?.type ? props?.type : "daily",
    },
    validationSchema: FormikSchema,
    onSubmit: async (values, action) => {
      let customObj = {
        doctor: values.doctor.map((key) => {
          return key.value;
        }),
        schedule: Number(props.schedules),
        start: moment(values.start_date + " " + values.start_time).format(
          "YYYY-MM-DDTHH:mm:ssZ"
        ),
        end: moment(
          moment(props.scheduleEnd).format("DD-MMMM-YYYY") +
            " " +
            values.end_time
        ).format("YYYY-MM-DDTHH:mm:ssZ"),
        days: values.days,
        type: values.type,
      };
      setDisableSubmitButton(!disableSubmitButton);
      let isSubmit = true;
      if (edit) {
        delete customObj["doctor"];
        isSubmit = await dispatch(editSlotFun(customObj, id));
      } else {
        isSubmit = await dispatch(
          assignSlotFun(customObj, props.calenderUpdate)
        );
      }
      if (!isSubmit.isSubmitButton) {
        setDisableSubmitButton(isSubmit.isSubmitButton);
        onHide();
        Formik.resetForm();
      }
    },
  });

  const handleCheckBox = (e, day) => {
    if (e.target.checked) {
      setSelectedDays((selectedDays) => [...selectedDays, day]);
    } else {
      const idx = selectedDays.indexOf(day);
      let arr = [...selectedDays];
      arr.splice(idx, 1);
      setSelectedDays([...arr]);
    }
  };

  useEffect(() => {
    Formik.setFieldValue("days", selectedDays);
    Formik.setFieldValue("doctor", selectedDoctors);
  }, [selectedDays, selectedDoctors]);

  useEffect(() => {
    setIsDays([
      ...GetDays(
        Formik.values.start_date,
        moment(props.scheduleEnd).format("DD-MMMM-YYYY")
      ),
    ]);
  }, [Formik.values.start_date, Formik.values.end_date]);

  // useEffect(() => {
  //   setIsDays([...GetDays(Formik.values.start_date, Formik.values.end_date)]);
  // }, []);
  useEffect(() => {
    if (Formik.values.type !== "custom") {
      setSelectedDays([...isDays]);
    }
  }, [isDays]);

  const [optionsType] = useState([
    {
      label: "Daily",
      value: "daily",
    },
    {
      label: "Weekly",
      value: "custom",
    },
  ]);
  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title className="h5">
          {edit ? props.title : "Add new slot"}
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={onHide} />
      </Modal.Header>
      <Form
        className="modal-content signUp_Form"
        onSubmit={Formik.handleSubmit}
      >
        <Modal.Body>
          <Row>
            {props.calenderUpdate.isAdmin && (
              <Col lg={12} xs={12} className="mt-2">
                <Form.Label>Select Doctors</Form.Label>
                <Form.Group className="mb-2">
                  <ReactSelect
                    isSearchable={true}
                    options={doctorsOptions}
                    value={selectedDoctors}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#61DAFB",
                        primary: "#61DAFB",
                      },
                    })}
                    styles={customSelectStyles}
                    isMulti
                    onChange={(arr) => {
                      setSelectedDoctors([...arr]);
                    }}
                    isDisabled={edit}
                  />
                  <Form.Control.Feedback type="invalid">
                    {Formik?.errors?.doctor ? "Please select doctors" : null}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
            {!props.calenderUpdate.isAdmin && (
              <Row className="mb-3">
                <Col xs={12} lg={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Department</Form.Label>
                    <Form.Control
                      type="text"
                      value={auth.doctor.department}
                      disabled
                      style={{ color: "#3B444B" }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Col xs={12} lg={6}>
              <Form.Group id="startDate">
                {auth.isAdmin ? (
                  <Form.Label>Select start date</Form.Label>
                ) : (
                  <Form.Label>Start date</Form.Label>
                )}
                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={(date) => {
                    Formik.setFieldValue(
                      "start_date",
                      moment(date).format("DD-MMMM-YYYY")
                    );
                  }}
                  isValidDate={(currDate) => {
                    return moment(
                      moment(currDate).format("DD-MMMM-YYYY")
                    ).isBetween(
                      moment(props.scheduleStart).subtract(1, "day"),
                      props.scheduleEnd
                    );
                  }}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <CalendarIcon className="icon icon-xs" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="YYYY-MM-DD"
                        value={Formik.values.start_date}
                        onFocus={openCalendar}
                        onChange={() => {}}
                        style={{ color: "#3B444B" }}
                        disabled={!auth.isAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.start_date}
                      </Form.Control.Feedback>
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={6}>
              <Form.Group id="endDate" className="mb-2">
                {auth.isAdmin ? (
                  <Form.Label>Select end date</Form.Label>
                ) : (
                  <Form.Label>End date</Form.Label>
                )}
                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={(date) => {
                    Formik.setFieldValue(
                      "end_date",
                      moment(date).format("DD-MMMM-YYYY")
                    );
                  }}
                  isValidDate={(currDate) => {
                    return moment(
                      moment(currDate).format("DD-MMMM-YYYY")
                    ).isBetween(
                      moment(Formik.values.start_date).subtract(1, "day"),
                      props.scheduleEnd
                    );
                  }}
                  initialViewDate={
                    Formik.values.end_date || Formik.values.start_date
                  }
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <CalendarIcon className="icon icon-xs" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="YYYY-MM-DD"
                        value={Formik.values.end_date}
                        onFocus={openCalendar}
                        onChange={() => {}}
                        style={{ color: "#3B444B" }}
                        disabled={!auth.isAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.end_date}
                      </Form.Control.Feedback>
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={6}>
              <Form.Group id="startTime">
                <Form.Label>Start time</Form.Label>
                <Datetime
                  onChange={(date) => {
                    Formik.setFieldValue(
                      "start_time",
                      moment(date).format("hh:mm a")
                    );
                  }}
                  closeOnSelect={true}
                  showTimeSelect
                  timeFormat="hh:mm a"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat={false}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <ClockIcon className="icon icon-xs" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="HH-MM-AM"
                        value={Formik.values.start_time}
                        onFocus={openCalendar}
                        onChange={() => {}}
                        style={{ color: "#3B444B" }}
                        disabled={!auth.isAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.start_time}
                      </Form.Control.Feedback>
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={6}>
              <Form.Group id="endTime" className="mb-2">
                <Form.Label>End time</Form.Label>
                <Datetime
                  onChange={(date) => {
                    Formik.setFieldValue(
                      "end_time",
                      moment(date).format("hh:mm a")
                    );
                  }}
                  closeOnSelect={true}
                  showTimeSelect
                  timeFormat="hh:mm a"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat={false}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <ClockIcon className="icon icon-xs" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="HH-MM-AM"
                        value={Formik.values.end_time}
                        onFocus={openCalendar}
                        onChange={() => {}}
                        style={{ color: "#3B444B" }}
                        disabled={!auth.isAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.end_time}
                      </Form.Control.Feedback>
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              {(() => {
                const durationInHours = moment(
                  Formik.values.end_date + " " + Formik.values.end_time,
                  "DD-MMMM-YYYY hh:mm a"
                ).diff(
                  moment(
                    Formik.values.start_date + " " + Formik.values.start_time,
                    "DD-MMMM-YYYY hh:mm a"
                  ),
                  "hours",
                  true
                );
                const durationInMinutes = Math.round(
                  (durationInHours - Math.floor(durationInHours)) * 60
                );
                return (
                  <p>
                    <strong>Slot Duration:</strong>{" "}
                    {Math.floor(durationInHours)} h {durationInMinutes} min
                  </p>
                );
              })()}
            </Col>
            {auth.isAdmin && (
              <Col xs={12} lg={12} md={12} sm={12} className="mt-2">
                <Form.Label>Select Type</Form.Label>
                <ReactSelect
                  isDisabled={!auth.isAdmin}
                  isSearchable={true}
                  options={optionsType}
                  value={optionsType.find(
                    (item) => item.value === Formik.values.type
                  )}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#61DAFB",
                      primary: "#61DAFB",
                    },
                  })}
                  styles={customSelectStyles}
                  onChange={(arr) => {
                    Formik.setFieldValue("type", arr?.value);
                    setSelectedDays([...days]);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {Formik?.errors?.type}
                </Form.Control.Feedback>
              </Col>
            )}
            {Formik.values.type === "custom" && auth.isAdmin && (
              <Col lg={12} xs={12} className="mt-2">
                <div className="d-flex align-items-center flex-wrap">
                  {weekArr.map((day, index) => {
                    return (
                      <Form.Check
                        key={index}
                        label={CapitalizeFirstLetter(day)}
                        id={day}
                        name="days"
                        htmlFor={day}
                        className="me-3"
                        checked={
                          selectedDays.includes(day) && isDays?.includes(day)
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          handleCheckBox(e, day);
                        }}
                      />
                    );
                  })}
                </div>
                <Form.Control.Feedback type="invalid">
                  {Formik?.errors?.days ? "Days are required" : null}
                </Form.Control.Feedback>
              </Col>
            )}
            {Formik.values.type === "custom" && !auth.isAdmin && (
              <Col lg={12} xs={12} className="mt-2">
                <h6>Repeats on: </h6>
                <div className="d-flex align-items-center flex-wrap">
                  {weekArr.map((day, index) => {
                    return (
                      <Form.Check
                        key={index}
                        label={CapitalizeFirstLetter(day)}
                        id={day}
                        name="days"
                        htmlFor={day}
                        className="me-3"
                        checked={selectedDays.includes(day) ? true : false}
                        style={{
                          color: selectedDays.includes(day)
                            ? "black"
                            : "grey",
                          pointerEvents: "none",
                        }}
                      />
                    );
                  })}
                </div>
                <Form.Control.Feedback type="invalid">
                  {Formik?.errors?.days ? "Days are required" : null}
                </Form.Control.Feedback>
              </Col>
            )}
          </Row>
        </Modal.Body>
        {props.calenderUpdate.isAdmin && (
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              variant="primary"
              type="submit"
              disabled={disableSubmitButton}
            >
              {disableSubmitButton ? (
                <Spinner animation="border" size="sm" />
              ) : edit ? (
                "Update"
              ) : (
                "Add"
              )}
            </Button>

            {edit && (
              <Button variant="danger" onClick={onDelete}>
                Unassign
              </Button>
            )}
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
};
