import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";

// export default [
//     {
//         "id": uuidv4(),
//         "title": "Call with Jane",
//         "start": moment().startOf("month").add(17, "days").toDate(),
//         "end": moment().startOf("month").add(17, "days").toDate(),
//         "allDay": true,
//         "className": "bg-danger border-0 shadow"
//     },
//     {
//         "id": uuidv4(),
//         "title": "Dinner meeting",
//         "start": moment().startOf("month").add(20, "days").toDate(),
//         "end": moment().startOf("month").add(20, "days").toDate(),
//         "allDay": true,
//         "className": "bg-secondary border-0 shadow"
//     },
//     {
//         "id": uuidv4(),
//         "title": "HackTM conference",
//         "start": moment().startOf("month").startOf("day").add(28, "days").toDate(),
//         "end": moment().startOf("month").endOf("day").add(29, "days").toDate(),
//         "allDay": false,
//         "className": "bg-success border-0 shadow"
//     },
//     {
//         "id": uuidv4(),
//         "title": "Meeting with John",
//         "start": moment().startOf("month").add(1, "month").toDate(),
//         "end": moment().startOf("month").add(1, "month").toDate(),
//         "allDay": true,
//         "className": "bg-blue border-0 shadow"
//     },
//     {
//         "id": uuidv4(),
//         "title": "Summer Hackaton",
//         "start": moment().startOf("month").startOf("day").add(2, "days").toDate(),
//         "end": moment().startOf("month").endOf("day").add(4, "days").toDate(),
//         "allDay": false,
//         "className": "bg-purple border-0 shadow"
//     },
//     {
//         "id": uuidv4(),
//         "title": "Digital event",
//         "start": moment().startOf("month").add(6, "days").toDate(),
//         "end": moment().startOf("month").add(8, "days").toDate(),
//         "allDay": false,
//         "className": "bg-info border-0 shadow"
//     },
//     {
//         "id": uuidv4(),
//         "title": "Marketing event",
//         "start": moment().startOf("month").add(1, "month").add(9, "days").toDate(),
//         "end": moment().startOf("month").add(1, "month").add(9, "days").toDate(),
//         "allDay": true,
//         "className": "bg-blue border-0 shadow"
//     },
//     {
//         "id": uuidv4(),
//         "title": "Dinner with Parents",
//         "start": moment().startOf("month").add(1, "month").add(20, "days").toDate(),
//         "end": moment().startOf("month").add(1, "month").add(20, "days").toDate(),
//         "allDay": true,
//         "className": "bg-blue border-0 shadow"
//     },
//     {
//         "id": uuidv4(),
//         "title": "Important meeting with John",
//         "start": moment().startOf("month").add(24, "days").toDate(),
//         "end": moment().startOf("month").add(24, "days").toDate(),
//         "allDay": true,
//         "className": "bg-danger border-0 shadow"
//     },
//     {
//         "id": uuidv4(),
//         "title": "Cyber Week",
//         "start": moment().startOf("month").add(1, "month").add(1, "day").toDate(),
//         "end"   : moment().startOf("month").add(1, "month").add(7, "days").toDate(),
//         "allDay": false,
//         "className": "bg-danger border-0 shadow"
//     }
// ];

export default [
  // { id: uuidv4(), title: "All Day Event", start: getDate("YEAR-MONTH-01") },
  // {
  //   id: uuidv4(),
  //   title: "Long Event",
  //   start: getDate("YEAR-MONTH-07"),
  //   end: getDate("YEAR-MONTH-10"),
  // },
  // {
  //   groupId: "999",
  //   id: uuidv4(),
  //   title: "Repeating Event",
  //   start: getDate("YEAR-MONTH-09T16:00:00+00:00"),
  // },
  {
    title: "Usama",
    doctor: 51,
    id: 6,
    start: '2022-11-09T16:00:00+00:00',
    end: "2022-11-10T16:00:00+00:00",
    days: ["monday", "tuesday"],

    // start: getDate("YEAR-MONTH-19"),
    // endDate: "2022-12-10",
    // startTime: "14:22:30",
    // endTime: "16:21:56",
  },
  // {
  //   groupId: "999",
  //   id: uuidv4(),
  //   title: "Repeating Event",
  //   start: getDate("YEAR-MONTH-16T16:00:00+00:00"),
  // },
  // {
  //   id: uuidv4(),
  //   title: "Conference",
  //   start: "YEAR-MONTH-17",
  //   end: getDate("YEAR-MONTH-19"),
  // },
  // {
  //   id: uuidv4(),
  //   title: "Meeting",
  //   start: getDate("YEAR-MONTH-18T10:30:00+00:00"),
  //   end: getDate("YEAR-MONTH-18T12:30:00+00:00"),
  // },
  // {
  //   id: uuidv4(),
  //   title: "Lunch",
  //   start: getDate("YEAR-MONTH-18T12:00:00+00:00"),
  // },
  // {
  //   id: uuidv4(),
  //   title: "Birthday Party",
  //   start: getDate("YEAR-MONTH-19T07:00:00+00:00"),
  // },
  // {
  //   id: uuidv4(),
  //   title: "Meeting",
  //   start: getDate("YEAR-MONTH-18T14:30:00+00:00"),
  // },
  // {
  //   id: uuidv4(),
  //   title: "Happy Hour",
  //   start: getDate("YEAR-MONTH-18T01:30:00+00:00"),
  // },
  // {
  //   id: uuidv4(),
  //   title: "Dinner",
  //   start: getDate("YEAR-MONTH-18T20:00:00+00:00"),
  // },
];

function getDate(dayString) {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }
  return dayString.replace("YEAR", year).replace("MONTH", month);
}
