

export const Routes = {

    // custom pages
    ListingView : {path: '/listing-view'},
    Settings: { path: "/settings" },
    AccountSetup: { path: "/accountSetup" },
    DepartDesign: { path: "/organizational_listing" },
    SchedularList: { path: "/schedular_list" },

    

    // pages
    DashboardOverview: { path: "/overview" },
    DashboardTraffic: { path: "/traffic-and-engagement" },
    DashboardProductAnalysis: { path: "/product-analysis" },
    Kanban: { path: "/kanban" },
    Messages: { path: "/messages" },
    SingleMessage: { path: "/message" },
    Users: { path: "/users" },
    Transactions: { path: "/transactions" },
    Tasks: { path: "/tasks" },
    Calendar: { path: "/calendar" },
    Map: { path: "/map" },
    Datatables: { path: "/datatables" },
    BootstrapTables: { path: "/bootstrap-tables" },
    Pricing: { path: "/pricing" },
    Billing: { path: "/billing" },
    Invoice: { path: "/invoice" },
    Signin: { path: "/" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password/:email/:token" },
    Lock: { path: "/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // plugins
    PluginCharts: { path: "/plugins/charts" },
    PluginCalendar: { path: "/plugins/calendar" },
    PluginDatatable: { path: "/plugins/datatable" },
    PluginMap: { path: "/plugins/map" },
    PluginDropzone: { path: "/plugins/dropzone" },
    PluginSweetAlert: { path: "/plugins/sweet-alert" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};