import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button } from "react-bootstrap";
import Table from "react-bootstrap-table-next";
import Pagination, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { CloudDownloadIcon } from "@heroicons/react/solid";
import { useDispatch } from "react-redux";
import { getDepartFun, getDesignFun } from "redux/departDesign/action";
import { getDrFun } from "redux/doctor/action";
import { getFilteredSchedulerFun } from "redux/scheduler/action";

export default ({
  data,
  columns,
  totalEntries,
  totalPage,
  tableType,
  currentPage,
  selectedDepartment,
  placeholder,
}) => {
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const { ExportCSVButton } = CSVExport;
  const dispatch = useDispatch();
  const customTotal = ({ page, sizePerPage }) => (
    <div>
      Showing {sizePerPage * page - 9} to {sizePerPage * page} of {totalEntries}{" "}
      entries
    </div>
  );
  const [searchTable, setSearchTable] = useState("");
  const customSizePerPage = (props) => {
    const { nextPageText, prePageText, page, onPageChange } = props;
    let pageArr = [];
    for (let i = 1; i <= totalPage; i++) {
      pageArr.push(i);
    }

    const onPageChanged = async (value) => {
      if (value <= totalPage && value != 0) {
        setDisableSubmitButton(!disableSubmitButton);
        let isSubmit = {};
        if (tableType === "department") {
          isSubmit = await dispatch(getDepartFun(value, searchTable));
        } else if (tableType === "designation") {
          isSubmit = await dispatch(getDesignFun(value, searchTable));
        } else if (tableType === "doctors") {
          isSubmit = await dispatch(getDrFun(value, searchTable));
        } else if (tableType === "schedules") {
          isSubmit = await dispatch(
            getFilteredSchedulerFun(
              value,
              searchTable,
              selectedDepartment?.value
            )
          );
        }
        if (!isSubmit?.isSubmitButton) {
          setDisableSubmitButton(isSubmit?.isSubmitButton);
        }
      }
    };

    return (
      <Row as="label">
        <Col xs="auto" style={{ zIndex: 0 }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <Button
                  variant="white"
                  className="page-link me-1"
                  disabled={disableSubmitButton}
                  onClick={() => {
                    onPageChanged(Number(currentPage) - 1);
                  }}
                >
                  {prePageText}
                </Button>
              </li>
              {pageArr.map((val) => {
                return (
                  <li
                    className={`${
                      val === Number(currentPage) ? "active" : ""
                    } page-item mx-1`}
                    key={val}
                  >
                    <Button
                      variant="white"
                      disabled={disableSubmitButton}
                      onClick={() => {
                        onPageChanged(val);
                      }}
                      className="page-link"
                    >
                      {val}
                    </Button>
                  </li>
                );
              })}
              <li className="page-item">
                <Button
                  variant="white"
                  className="page-link ms-1"
                  disabled={disableSubmitButton}
                  onClick={() => {
                    onPageChanged(Number(currentPage) + 1);
                  }}
                >
                  {nextPageText}
                </Button>
              </li>
            </ul>
          </nav>
        </Col>
      </Row>
    );
  };

  const [tableData, setTableData] = useState([]);

  const onSearchTable = async (val) => {
    if (val === searchTable) {
      return 0;
    }
    setSearchTable(val);
    let isSubmit = {};
    if (tableType === "department") {
      isSubmit = await dispatch(getDepartFun(1, val));
    } else if (tableType === "designation") {
      isSubmit = await dispatch(getDesignFun(1, val));
    } else if (tableType === "doctors") {
      isSubmit = await dispatch(getDrFun(1, val));
    } else if (tableType === "schedules") {
      isSubmit = await dispatch(
        getFilteredSchedulerFun(1, val, selectedDepartment?.value)
      );
    }
  };

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  return (
    <>
      <ToolkitProvider
        keyField="id"
        search={true}
        columns={columns}
        data={tableData}
        exportCSV
      >
        {({ baseProps, searchProps, csvProps }) => (
          <PaginationProvider
            pagination={Pagination({
              custom: true,
              showTotal: true,
              alwaysShowAllBtns: true,
              totalSize: tableData?.length,
              withFirstAndLast: false,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <Card>
                <div className="table-responsive py-4">
                  <div className="dataTable-top">
                    <div className="dataTable-search">
                      <Search.SearchBar
                        srText=""
                        {...searchProps}
                        placeholder={placeholder}
                        delay={2}
                        onSearch={(searchText) => {
                          onSearchTable(searchText);
                        }}
                        searchText={searchTable}
                        style={{ width: "150%" }}
                      />
                    </div>
                    <ExportCSVButton {...csvProps}>
                      <Button variant="primary" className="m-1">
                        Download{" "}
                        <CloudDownloadIcon className="icon icon-xs ms-1" />
                      </Button>
                    </ExportCSVButton>
                  </div>

                  <Table
                    {...baseProps}
                    {...paginationTableProps}
                    headerWrapperClasses="thead-light"
                    bodyClasses="border-0"
                    rowClasses="border-bottom"
                    classes="table-flush dataTable-table"
                  />

                  <div className="dataTable-bottom">
                    <div className="dataTable-pagination me-3">
                      {customSizePerPage(paginationProps)}
                    </div>
                    <div className="dataTable-info">
                      {customTotal(paginationProps)}
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </PaginationProvider>
        )}
      </ToolkitProvider>
    </>
  );
};
