import * as types from "./contants";
import customAxios from "../../config/index";
import { toast } from "react-toastify";

// Add Doctor Function
export const addDrFun = (data) => async (dispatch) => {
  try {
    const response = await customAxios.post("doctors/add/", data);
    toast.success(response.data.detail);
    dispatch(getDrFun(1));
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Account Setup Function
export const accSetUpFun = (data, history) => async (dispatch) => {
  try {
    const response = await customAxios.post("doctors/setup/", data);
    dispatch({
      type: types.ACC_SETUP,
      payload: response.data,
    });
    toast.success("Successfully Updated");
    history.push("/");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};

// Get Doctor Function
export const getDrFun =
  (page, search, filter = "") =>
  async (dispatch) => {
    try {
      let URL = `doctors/?setup=${filter}`;
      if (page) {
        URL = URL + `&page=${page}`;
      }
      if (search) {
        URL = URL + `&search=${search}`;
      }
      const response = await customAxios.get(URL);
      dispatch({
        type: types.GET_DR,
        payload: response.data,
      });
      // if (response.data.data.length == 0) {
      //   toast.error("No Record Found!");
      // }
    } catch (error) {
      toast.error(error?.response?.data?.detail);
      return { isSubmitButton: false };
    }
  };

// Get Doctor Function
export const getDrByIdFun = (id) => async (dispatch) => {
  try {
    const response = await customAxios.get(`users/${id}`);
    dispatch({
      type: types.GET_USER_BY_ID,
      payload: response.data,
    });
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};

// Edit Doctor Function
export const editDrFun = (data, id) => async (dispatch) => {
  try {
    const response = await customAxios.patch(`users/edit/${id}`, data);
    dispatch({
      type: types.EDIT_USER,
      payload: response.data,
    });
    toast.success("Successfully Updated");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};

// Delete Doctor Function
export const delDrFun = (data) => async (dispatch) => {
  try {
    const response = await customAxios.post(`users/delete/`, data);
    dispatch(getDrFun(1));
    toast.success(response.data.detail);
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};
