import * as types from "./contants";

const initialState = {
  token: "",
  refresh: "",
  isAdmin: "",
  name: "",
  email: "",
  phone: "",
  dob: "",
  gender: "",
  isAccountSetup: "",
  isDoctor: "",
  isPatient: "",
  doctor: null,
  patient: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUTH: {
      return {
        ...state,
        token: action.payload?.access,
        refresh: action.payload?.refresh,
        isAdmin: action.payload?.isAdmin,
        name: action.payload?.name,
        email: action.payload?.email,
        phone: action.payload?.phone,
        dob: action.payload?.dob,
        gender: action.payload?.gender,
        isAccountSetup: action.payload?.isAccountSetup,
        isDoctor: action.payload?.isDoctor,
        isPatient: action.payload?.isPatient,
        doctor: action.payload?.doctor,
        patient: action.payload?.patient,
      };
    }
    case types.ACC_SETUP: {
      let dataa = action.payload;
      return {
        ...state,
        ...dataa,
      };
    }
    case types.REFRESH_AUTH: {
      return {
        ...state,
        token: action.payload?.access,
      };
    }
    default:
      return state;
  }
}
