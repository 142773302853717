import * as types from "./contants";
import customAxios from "../../config/index";
import { toast } from "react-toastify";

// Login Function
export const loginFun = (data, history) => async (dispatch) => {
  try {
    const response = await customAxios.post("auth/login/", data);
    dispatch({
      type: types.AUTH,
      payload: response.data,
    });
    history.push("/");
    toast.success("Successfully Login");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Logout Function
export const logoutFun = (token) => async (dispatch) => {
  let data = { refresh: token };
  try {
    const response = await customAxios.post("auth/logout/", data);
    dispatch({
      type: types.AUTH_LOGOUT,
      payload: null,
    });
    toast.success("Logout Successfully");
    return { isSubmitButton: false };
  } catch (error) {
    dispatch({
      type: types.AUTH_LOGOUT,
      payload: null,
    });
    toast.success("Logout Successfully");
    return { isSubmitButton: false };
  }
};

// Registration Function
export const registerFun = (data, history) => async (dispatch) => {
  try {
    const response = await customAxios.post("auth/register/", data);
    dispatch({
      type: types.AUTH,
      payload: response.data,
    });
    history.push("/");
    toast.success("Successfully Registered 🎉");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Registration Function
export const profileUpdateFun = (data) => async (dispatch) => {
  try {
    const response = await customAxios.patch("users/profile/update/", data);
    dispatch({
      type: types.ACC_SETUP,
      payload: response.data,
    });
    toast.success("Successfully Updated");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};

// Forget Password Function
export const forgetFun = (data, history) => async (dispatch) => {
  try {
    const response = await customAxios.post("auth/forget/", data);
    toast.success(response.data.detail);
    history.push("/");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Reset Password Function
export const resetFun = (data, token, history) => async (dispatch) => {
  try {
    customAxios.interceptors.request.use(
      (config) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    const response = await customAxios.post("auth/reset/", data);
    toast.success(response.data.detail);
    history.push("/");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// export const refreshTokenFun =
//   (baseUrl, data) => async (dispatch) => {
//     axios
//       .post(`${baseUrl}users/token/refresh/`, data)
//       .then((res) => {
//         // localStorage.setItem('tok', res.data.access)
//         dispatch({
//           type: types.REFRESH_AUTH,
//           payload: res.data,
//         });
//         return res.data.access;
//       })
//       .catch((error) => {
//         // dispatch(logoutFun())
//         toast.error("Session has been expired!");
//       });
//   };
