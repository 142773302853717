import * as types from "./contants";

const initialState = {
  allDoctor: {},
  data: [],
  user: {}
};

export default function allDoctors(state = initialState, action) {
  switch (action.type) {
    case types.GET_DR: {
      return {
        ...state,
        allDoctor: action.payload,
        data: action.payload.data,
      };
    }
    case types.GET_USER_BY_ID: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case types.EDIT_USER: {
      return {
        ...state,
        data: state.data?.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    }
    default:
      return state;
  }
}
